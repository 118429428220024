import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import { FileFragment } from 'generated/graphql';

const Video: React.FC<{ file: FileFragment; className?: string }> = ({ file, className }) => {
    const video = useRef<HTMLVideoElement>(null);

    const [player, setPlayer] = useState<videojs.Player>();

    useEffect(() => {
        if (file.url && !player) {
            setPlayer(
                videojs(video.current, {
                    sources: [{ src: file.url.original, type: file.file_type }],
                    controls: true,
                    autoplay: true
                })
            );
        }

        return () => {
            if (player) {
                player.dispose();
            }
        };
    }, [file, player]);

    return <video ref={video} className={`${className} video-js`} />;
};

export default styled(Video)`
    margin: auto;
`;
