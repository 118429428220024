import React from 'react';
import styled from 'styled-components';

const NotSupported: React.FC<{ className?: string }> = ({ className }) => {
    return <p className={className}>Impossible de prévisualiser ce type de document, veuillez le télécharger.</p>;
};

export default styled(NotSupported)`
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;
`;
