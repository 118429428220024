import React from 'react';
import styled from 'styled-components';
import { ReactComponent as NoDataPicto } from '../static/img/undraw_no_data_qbuo.svg';

const NoData: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <figure className={className}>
            <NoDataPicto className="picto" />
            <figcaption className="message">Aucun élément à afficher</figcaption>
        </figure>
    );
};

export default styled(NoData)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .picto {
        width: 170px;
    }

    .message {
        text-align: center;
        margin-top: 10px;
    }
`;
