import styled from 'styled-components';

export default styled.button`
    padding: 12px 24px;
    background-color: #5457e6;
    color: #fff;
    border-radius: 6px;
    border: none;
    font-size: 15px;

    @media (prefers-reduced-motion: no-preference) {
        transition: 0.4s ease-in-out;
    }

    &:hover,
    &:focus {
        box-shadow: 0 5px 25px rgba(28, 53, 150, 0.25);
    }
`;
