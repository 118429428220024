import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: row;

    margin-bottom: 10px;

    font-weight: regular;
    font-size: 13px;
    color: ${({ theme }) => theme.greyTitle};

    .col-main {
        display: flex;
        flex-direction: column;
        flex: 2 0;
    }
    .col-sec {
        display: flex;
        flex-direction: column;
        flex: 5 0;
    }

    .col-next {
        display: flex;
        flex-direction: column;
        flex: 1 0;
    }
`;
