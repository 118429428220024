import { useLayoutEffect } from 'react';

export default (opened: boolean) => {
    useLayoutEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        if (opened) {
            // Prevent scrolling on mount
            document.body.style.overflow = 'hidden';
        }
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, [opened]);
};
