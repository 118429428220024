import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CloseButton from 'components/modal/CloseButton';
import useBlockScroll from 'hook/useBlockScroll';
import FileModal from 'components/modal/FileModal';
import Image from 'components/Image';
import Picture from 'components/FileItem/Picture';
import Plan from './Plan';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { RemarkFragment, PositionFragment, DocumentFragment } from 'generated/graphql';

const RemarkModal: React.FC<{
    className?: string;
    close: () => void;
    opened: boolean;
    remark: RemarkFragment & { remarkIndex: string; labelStatus: string };
}> = ({ className, opened, close, remark }) => {
    useBlockScroll(opened);

    const image = remark.status[remark.status.length - 1].image;

    const [fileOpened, setOpened] = React.useState(false);

    const toggle = () => setOpened(!fileOpened);

    const remarkStatus = remark.status[remark.status.length - 1].properties.status;
    const status = typeof remarkStatus === 'string' ? parseInt(remarkStatus) : remarkStatus;

    const documentWithPositions =
        remark.positions &&
        Object.values(
            remark.positions.reduce(
                (
                    acc: Record<
                        string,
                        DocumentFragment & { positions: { x: number; y: number; name: string; status: number }[] }
                    >,
                    position: PositionFragment
                ) => {
                    const {
                        properties: { x, y }
                    } = position;
                    if (position.document) {
                        const existingDoc = acc[position.document._id];
                        if (existingDoc) {
                            acc[position.document._id] = {
                                ...existingDoc,
                                positions: [
                                    ...existingDoc.positions,
                                    { x: parseFloat(x), y: parseFloat(y), name: remark.remarkIndex, status }
                                ]
                            };
                        } else {
                            acc[position.document._id] = {
                                ...position.document,
                                positions: [{ x: parseFloat(x), y: parseFloat(y), name: remark.remarkIndex, status }]
                            };
                        }
                    }

                    return acc;
                },
                {}
            )
        );

    return opened ? (
        <div className={className}>
            <div className="overlay" onClick={close} />
            <div className="modal" aria-label="modal">
                <div className="modal_toolbar">
                    <CloseButton onClick={close} />

                    <h2 className="title">{`Remarque ${remark.remarkIndex}`}</h2>
                    <span />
                </div>

                <div className="wrapper">
                    <div className="content">
                        <section className="property">
                            <h3 className="property_title">Statut</h3>
                            <p className="property_value">{remark.labelStatus}</p>
                        </section>
                        {remark.properties.descriptionText && (
                            <section className="property">
                                <h3 className="property_title">Description</h3>
                                <p className="property_value">{remark.properties.descriptionText}</p>
                            </section>
                        )}

                        {remark.properties.localization && remark.properties.localization.length > 0 && (
                            <section className="property">
                                <h3 className="property_title">Localisation</h3>
                                <p className="property_value">
                                    {`${remark.properties.localization
                                        .map(localization => localization.name)
                                        .join(', ')}
                                    `}
                                </p>
                            </section>
                        )}

                        {remark.documents && remark.documents.length > 0 && (
                            <section className="property">
                                <h3 className="property_title">Images</h3>

                                <div className="pictures">
                                    {image?.url && (
                                        <div className="picture_wrapper">
                                            <Image
                                                className="picture"
                                                image={image}
                                                alt={image.properties.name || ''}
                                                onClick={toggle}
                                            />
                                            <FileModal
                                                file={{ ...image, properties: { name: image.properties.name || '' } }}
                                                opened={fileOpened}
                                                close={toggle}
                                            >
                                                <Picture src={image.url.original} alt={image.properties.name || ''} />
                                            </FileModal>
                                        </div>
                                    )}
                                    {remark.documents.filter(Boolean).map(doc =>
                                        doc?.url ? (
                                            <div className="picture_wrapper" key={doc._id}>
                                                <Image
                                                    className="picture"
                                                    image={doc}
                                                    alt={doc.properties.name || ''}
                                                    onClick={toggle}
                                                />
                                                <FileModal file={doc} opened={fileOpened} close={toggle}>
                                                    <Picture src={doc.url.original} alt={doc.properties?.name || ''} />
                                                </FileModal>
                                            </div>
                                        ) : null
                                    )}
                                </div>
                            </section>
                        )}
                        {documentWithPositions && documentWithPositions.length > 0 && (
                            <section className="property">
                                <h3 className="property_title">Positions sur plan</h3>
                                <div className="plans">
                                    {documentWithPositions.map(doc => (
                                        <Plan key={doc._id} document={doc} />
                                    ))}
                                </div>
                            </section>
                        )}
                        {remark.observations && remark.observations.length > 0 && (
                            <section className="property">
                                <h3 className="property_title">Commentaires</h3>
                                <div className="observations">
                                    {remark!.observations.map(observation => (
                                        <section key={observation._id}>
                                            <span className="observation_title">
                                                <h4 className="observation_title_name">
                                                    {observation.info?.user_create_name}
                                                </h4>
                                                {observation.info?.edit_date && (
                                                    <time className="observation_title_date">
                                                        {format(
                                                            parseISO(observation.info.edit_date),
                                                            'dd/MM/yyyy HH:mm:ss'
                                                        )}
                                                    </time>
                                                )}
                                            </span>

                                            <p className="observation_message">"{observation.properties.message}"</p>
                                        </section>
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

const StyledRemarkModal = styled(RemarkModal)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .overlay {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: hsla(0, 0%, 100%, 0.6);
        cursor: pointer;
        backdrop-filter: blur(2px);
    }

    .modal {
        z-index: 3;
        border-radius: 7px;
        width: 600px;
        max-height: calc(100vh - 60px);
        background-color: #fff;
        box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
    }

    .modal_toolbar {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        box-shadow: 0 8px 10px rgba(28, 53, 150, 0.06);
        z-index: 1;
    }

    .title {
        text-indent: 14px;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.blackTitle};
        margin-left: -40px;
    }

    .wrapper {
        overflow: auto;
    }

    .property {
        padding: 10px 14px;
        &:not(:last-child) {
            border-bottom: 1px solid #ececec;
        }
    }

    .property_title {
        font-size: 13px;
        font-weight: 400;
        color: ${({ theme }) => theme.greyTitle};
        margin-bottom: 0;
    }

    .property_value {
        font-size: 14px;
        color: #465576;
    }

    .picture_wrapper {
        width: 150px;
        height: 150px;
        margin: 10px;
        border-radius: 5px;
        box-shadow: 0 0 30px rgba(28, 53, 150, 0.07);
    }

    .picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        cursor: pointer;
    }

    .pictures {
        display: flex;
        flex-wrap: wrap;
    }

    .plans {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 10px;
        margin-left: -5px;
    }

    .observations {
        color: #465576;
        > * {
            border-radius: 4px;
            border: 1px solid #ececec;
            margin: 5px 0;
            padding: 1rem;
        }
    }

    .observation_title {
        display: flex;
        align-items: baseline;
        padding: 5px;

        .observation_title_name {
            margin: 0;
        }

        time {
            font-size: 11px;
            margin-left: 5px;
        }
    }

    .observation_message {
        margin: 0;
    }
`;

export default function Modal(props: {
    close: () => void;
    opened: boolean;
    remark: RemarkFragment & { remarkIndex: string; labelStatus: string };
}) {
    return ReactDOM.createPortal(<StyledRemarkModal {...props} />, document.querySelector('#modal') as Element);
}
