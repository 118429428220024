import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import RemarkModal from './RemarkModal';
import { RemarkFragment } from 'generated/graphql';

const getMainStatusLabel = (status: number | string): string => {
    switch (typeof status === 'string' ? parseInt(status) : status) {
        case 0:
            return 'Status_RemarkOpenAt';
        case 1:
            return 'Status_RemarkWaitAt';
        case 2:
            return 'Status_CloseAt';
        case 3:
            return 'Status_RemarkLimitDateAt';
        case 4:
            return 'Status_RemarkQuitusAt';
        case 5:
            return 'Status_RemarkForMemoryAt';
        case 6:
            return 'Status_RemarkDisplayedAt';
        case 7:
            return 'Status_RemarkHiddenAt';
        case 8:
            return 'Status_RemarkNotValidatedAt';
        case 9:
            return 'Status_RemarkValidatedAt';
        case 10:
            return 'Status_RemarkToDoAt';
        case 11:
            return 'Status_PartialComplianceAt';
        case 12:
            return 'Status_NoncomplianceAt';
        case 13:
            return 'Status_ControlledAndCompliantAt';
        case 14:
            return 'Status_RemarkArchivedAt';
        case 15:
            return 'Status_UnapplicableAt';
        case 16:
            return 'Status_PendingAt';
        default:
            return '';
    }
};

const getMainStatusDate = (remark: RemarkFragment): string =>
    format(parseISO(remark.status[remark.status.length - 1].properties.date), 'dd/MM/yyyy');

const RemarkItem = ({
    remark,
    className
}: {
    className?: string;
    remark: RemarkFragment & { remarkIndex: string };
}) => {
    const [t] = useTranslation();

    const [opened, setOpened] = React.useState(false);

    const toggle = () => setOpened(!opened);

    const status = remark.status[remark.status.length - 1].properties.status;
    const labelStatus = `${t(getMainStatusLabel(status))} ${t(getMainStatusDate(remark))}`;

    return (
        <>
            <button className={className} onClick={toggle}>
                <div className="remark-index">
                    <span className="index">{remark.remarkIndex}</span>
                </div>

                <div className="remark-content">
                    <p className="info">{remark.properties.descriptionText}</p>

                    {remark.properties.localization && remark.properties.localization.length > 0 && (
                        <p className="remark-loc">
                            {`Localisation : ${remark.properties.localization
                                .filter(localization => localization) // may be null (according to error reported in sentry)
                                .map(localization => localization.name)
                                .join(', ')}`}
                        </p>
                    )}
                </div>
                <div className="remark-indicator">
                    <Indicator status={typeof status === 'string' ? parseInt(status) : status} />
                </div>
            </button>
            <RemarkModal remark={{ ...remark, labelStatus }} close={toggle} opened={opened} />
        </>
    );
};

const STATUS_CLOSED = 2;

const Indicator = styled.div<{ status: number }>`
    background-color: ${props => (props.status === STATUS_CLOSED ? '#36CE6F' : '#FF4F3D')};
    box-shadow: ${props => (props.status === STATUS_CLOSED ? '0px 0px 40px #36ce6f' : '0px 0px 40px #ff4f3d')};
`;

export default styled(RemarkItem)`
    .remark-index {
        margin-left: 6px;
        margin-right: 10px;
        border-radius: 6px;
        border: 2px solid ${({ theme }) => theme.blackTitle};
        width: fit-content;
        display: flex;
        min-width: 31px;
        height: 28px;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 500;
        color: ${({ theme }) => theme.blackTitle};
    }

    .remark-content {
        display: flex;
        flex-direction: column;
        flex: 5 0;

        p {
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.greyTitle};
            margin: 0;

            &.remark-loc {
                font-weight: 500;
                font-size: 13px;
                color: ${({ theme }) => theme.blackTitle};
            }
        }
    }

    .remark-indicator {
        padding-right: 10px;
        div {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
    }

    .chevron {
        width: 8px;
    }
`;
