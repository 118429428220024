import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { ReactComponent as Back } from '../static/img/back.svg';
import { ReactComponent as Arrow } from '../static/img/right-arrow.svg';

const Ariane: React.FC<{
    previous: ({ url: string; label: string })[];
    current: string | { label: string; onClick: () => void };
    next?: ({ onClick: () => void; label: string })[];
    className?: string;
}> = ({ previous, next, current, className }) => {
    return (
        <nav className={className}>
            <ul className="links">
                <li>
                    {next && next.length > 1 ? (
                        <button onClick={next[0].onClick}>
                            <Back className="back" />
                        </button>
                    ) : typeof current !== 'string' ? (
                        <button onClick={current.onClick}>
                            <Back className="back" />
                        </button>
                    ) : (
                        <Link to={previous[previous.length - 1].url}>
                            <Back className="back" />
                        </Link>
                    )}
                </li>
                {previous.map(({ label, url }, index) => (
                    <li key={label}>
                        <Link to={url}>{label}</Link>
                        <Arrow className="separator" />
                    </li>
                ))}
                {typeof current === 'string' ? (
                    <li className="current">{current}</li>
                ) : (
                    <li className="current">
                        <button onClick={current.onClick}>{current.label}</button>
                    </li>
                )}

                {next &&
                    next.map(({ label, onClick }, index) => (
                        <li key={label}>
                            <Arrow className="separator" />
                            <button onClick={onClick}>{label}</button>
                        </li>
                    ))}
            </ul>
        </nav>
    );
};

export default styled(Ariane)`
    .links {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 30px;
        padding-left: 0;

        li {
            display: flex;
            align-items: center;
            font-size: 14px;
        }

        a,
        button {
            text-decoration: none;
            display: flex;
            align-items: center;
            color: #465576;
            background: none;
            border: none;
            padding: 0;
        }

        a,
        .current,
        .current button {
            font-weight: 500;
        }

        .current,
        .current button {
            color: #5457e6;
        }
    }

    .back {
        box-shadow: 0 0 10px rgba(98, 90, 255, 0.15);
        margin-right: 15px;
        border-radius: 50%;
        width: 30px;

        &:hover,
        &:focus {
            box-shadow: 0 0 20px rgba(72, 66, 201, 0.2);
        }
    }

    .separator {
        margin: 0 10px;
    }
`;
