import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;

    background-color: white;
    box-shadow: var(--main_shadow);
    border-radius: 8px;

    overflow: hidden;

    .col-main {
        display: flex;
        flex-direction: column;
        flex: 2 0;
    }
    .col-sec {
        display: flex;
        flex-direction: column;
        flex: 5 0;
    }

    .col-next {
        display: flex;
        flex-direction: column;
        flex: 1 0;
    }

    button {
        height: 60px;
    }

    .row {
        height: 50px;
    }

    button,
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 20px;
        text-decoration: none;
        color: inherit;
        text-align: left;
        background-color: white;
        cursor: pointer;
        outline: 0;

        border: none;
        border-bottom: 1px solid #e3e7f0;

        p {
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &:last-child {
            border-bottom: none;
        }

        .col-main {
            text-indent: 14px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.blackTitle};
        }

        .col-sec {
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.greyTitle};
            margin: 0;
        }

        .col-next {
            img {
                margin-left: auto;
                margin-right: 20px;
            }
        }

        &:hover,
        &:focus {
            background-color: #fbfbfd;
        }
    }
`;
