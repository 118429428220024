import React, { useState, useEffect } from 'react';
import ErrorModal from 'components/modal/ErrorModal';
import useQuery from 'hook/useQuery';
import styled from 'styled-components';
import NoData from 'components/NoData';
import Loader from 'components/Loading';
import Toolbar from 'components/Toolbar';
import Ariane from 'components/Ariane';
import { useTranslation } from 'react-i18next';
import ListTitle from 'components/ListTitle';
import TableList from 'components/TableList';
import RemarkItem from './RemarkItem';
import xlsx from 'xlsx';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { useGetProjectAreaReportsQuery } from 'generated/graphql';

const STATUS_CLOSED = '2';

const RemarkList = ({
    projectId = '',
    areaId = '',
    className
}: {
    path?: string;
    projectId?: string;
    areaId?: string;
    className?: string;
}) => {
    const { data, loading, error: queryError } = useQuery(useGetProjectAreaReportsQuery, {
        variables: { projectId, areaId }
    });

    const [error, setError] = useState<boolean>(false);

    useEffect(() => setError(!!queryError), [queryError]);

    const toggle = () => setError(!error);

    const [t] = useTranslation();

    const reports = data?.area?.reports?.map((report, reportIndex) => ({
        ...report,
        remarks: report.remarks?.map((remark, index) => ({
            ...remark,
            remarkIndex: `${reportIndex + 1}.${index + 1}`
        }))
    }));

    const exportXls = () => {
        const exported =
            reports &&
            reports.reduce(
                (
                    acc: {
                        Operation: string;
                        Acquereur: string;
                        Visite: string;
                        N: string;
                        Localisation: string;
                        Description?: string | null;
                        Statut: string;
                        Date: string;
                    }[],
                    report
                ) => [
                    ...acc,
                    ...(report.remarks?.map(remark => ({
                        Operation: data && data.project ? data.project.properties.name : '',
                        Acquereur:
                            data && data.area && data.area.actors
                                ? data.area.actors
                                      .map(
                                          actor =>
                                              `${actor.properties.firstname || ''} ${actor.properties.lastname || ''}`
                                      )
                                      .join(', ')
                                : '',
                        Visite: report.properties.name,
                        N: remark.remarkIndex,
                        Localisation: remark.properties.localization
                            ? remark.properties.localization.map(localization => localization.name).join(', ')
                            : '',
                        Description: remark.properties.descriptionText,
                        Statut:
                            remark.status[remark.status.length - 1].properties.status.toString() === STATUS_CLOSED
                                ? 'Levée'
                                : 'En cours',
                        Date: format(parseISO(remark.status[remark.status.length - 1].properties.date), 'dd/MM/yyyy')
                    })) || [])
                ],
                []
            );

        /* make the worksheet */
        const ws = xlsx.utils.json_to_sheet(exported || []);

        /* add to workbook */
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Remarques');
        xlsx.writeFile(wb, `${data && data.area ? data.area.properties.NUMERO_COMMERCIAL_LOT : ''}.xlsx`);
    };

    return (
        <div className={className}>
            <Toolbar>
                <Ariane
                    previous={[
                        { url: '/', label: 'Projets' },
                        {
                            url: `/project/${projectId}`,
                            label: data && data.project ? `${data.project.properties.name} : Logements` : ''
                        }
                    ]}
                    current={(data && data.area && data.area.properties.NUMERO_COMMERCIAL_LOT) || ''}
                />
                <div className="export toolbar-right">
                    <button onClick={exportXls}>Export</button>
                </div>
            </Toolbar>
            <ListTitle>Réserves</ListTitle>

            {loading && <Loader />}

            {data && data.area ? (
                <>
                    <div className="data">
                        {reports &&
                            reports.map((report, index) => (
                                <div className="reports" key={report._id}>
                                    <section className="report">
                                        <div className="report_intro">
                                            <h2 className="report_name">{`${index + 1}. ${report.properties.name}`}</h2>
                                            <p className="report_date">
                                                {format(parseISO(report.properties.date), 'Le dd/MM/yyyy')}
                                            </p>
                                        </div>

                                        {data.area?.properties.NOM ? (
                                            <address className="actor">
                                                <p>{data.area.properties.NOM}</p>

                                                {data.area.properties.TEL && (
                                                    <p>{`Téléphone: ${data.area.properties.TEL}`}</p>
                                                )}
                                            </address>
                                        ) : (
                                            report.actors &&
                                            report.actors.map(actor => (
                                                <address className="actor" key={actor._id}>
                                                    <p>{`${
                                                        actor.properties.lastname ? `${actor.properties.lastname} ` : ''
                                                    }${actor.properties.firstname || ''}`}</p>
                                                    {actor.properties.address && <p>{actor.properties.address}</p>}
                                                    <p>{`${actor.properties.zipcode} ${actor.properties.town}`}</p>
                                                    {actor.properties.phone && (
                                                        <p>{`Téléphone: ${actor.properties.phone}`}</p>
                                                    )}
                                                </address>
                                            ))
                                        )}

                                        <div>
                                            <p className="id_unique">
                                                {data && data.area && data.area.properties.ID_UNIQUE
                                                    ? `ID unique : ${data.area.properties.ID_UNIQUE}`
                                                    : ''}
                                            </p>
                                        </div>
                                    </section>
                                    <TableList className="remarks">
                                        {report.remarks?.map(remark => (
                                            <RemarkItem key={remark._id} remark={remark} />
                                        ))}
                                    </TableList>
                                </div>
                            ))}
                        {reports && reports.length === 0 && <NoData />}
                    </div>
                </>
            ) : (
                !loading && <NoData />
            )}
            <ErrorModal opened={error} close={toggle}>
                {t('ErrorMessage_ErrorMessage')}
            </ErrorModal>
        </div>
    );
};

export default styled(RemarkList)`
    --report_width: 250px;

    .export {
        button {
            height: 30px;
            padding: 0px 14px;
            background: white;
            border-radius: 4px;
            box-shadow: var(--main_shadow);
            border: none;
            cursor: pointer;
            color: ${({ theme }) => theme.greyTitle};
            font-size: 14px;
            font-weight: 500;
            outline: 0;
            transition-duration: 0.3s;

            &:hover {
                box-shadow: var(--hard_shadow);
            }
            &:focus {
                background-color: #f7f7f7;
            }
        }
    }

    .data {
        display: flex;
        flex-direction: column;
    }

    .reports {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        border-radius: 8px;
        background-color: white;
        box-shadow: var(--main_shadow);
        overflow: hidden;

        margin-bottom: 14px;
    }

    .report_intro {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 14px;
    }

    .report_name {
        display: inline;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.blackTitle};
    }

    .report_date {
        display: inline;
        font-weight: 500;
        margin: 0;
        font-size: 12px;
        color: ${({ theme }) => theme.greyTitle};
    }

    .actor {
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.greyTitle};
        margin: 0;
        font-style: inherit;

        p {
            margin: 0;
            line-height: 23px;
        }
    }

    .id_unique {
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.greyTitle};
        margin: 0;
        font-style: inherit;
        margin-top: 10px;

        p {
            margin: 0;
            line-height: 23px;
        }
    }

    .report {
        padding: 14px;
        border-bottom: 1px solid #0000001f;
        padding-bottom: 10px;
    }

    .remarks {
        box-shadow: none;
    }
`;
