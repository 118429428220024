import React, { useContext } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Logged } from 'components/Main';
import { useTranslation } from 'react-i18next';
import { updatePassword } from 'api/authent';
import LoginButton from 'components/LoginButton';
import ErrorModal from 'components/modal/ErrorModal';

import useBoolean from 'hook/useBoolean';

const Button = styled(LoginButton)`
    width: fit-content;
    margin: 20px 0;
    align-self: end;
`;

const ChgPassword: React.FC<{ path?: string; className?: string }> = ({ className }) => {
    const { user } = useContext(Logged);

    const [error, { setValue: setError, toggle }] = useBoolean(false);

    const { t } = useTranslation();

    const [sent, { toggle: toggleSent }] = useBoolean(false);

    return (
        <main className={className}>
            <Formik
                initialValues={{
                    newPassword: '',
                    newPasswordConfirm: ''
                }}
                onSubmit={async (
                    values: {
                        newPassword: string;
                        newPasswordConfirm: string;
                    },
                    { setSubmitting, setStatus, setErrors }
                ) => {
                    if (values.newPassword === values.newPasswordConfirm) {
                        try {
                            await updatePassword(user.token, values.newPassword);
                            toggleSent();
                            setSubmitting(false);
                        } catch (err) {
                            toggle();
                            setError(true);
                            setSubmitting(false);
                        }
                    } else {
                        setErrors({ newPasswordConfirm: t('Arbox_InvalidConfirmationPassword') });
                    }
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    errors
                    /* and other goodies */
                }) => (
                    <form className="form" onSubmit={handleSubmit}>
                        <h1 className="title">{t('Global_ChangePassword')}</h1>
                        {errors && <div className="error">{errors.newPasswordConfirm}</div>}
                        <input
                            type="password"
                            name="newPassword"
                            className="user_info_input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                            required
                            aria-label={t('Arbox_Account_New_Password')}
                            placeholder={t('Arbox_Account_New_Password')}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$"
                            title={t('Web_Create_Account_Password_Secure')}
                        />
                        <input
                            type="password"
                            name="newPasswordConfirm"
                            className="user_info_input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPasswordConfirm}
                            required
                            placeholder={t('Global_Confirm')}
                            aria-label={t('Global_Confirm')}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$"
                            title={t('Web_Create_Account_Password_Secure')}
                        />

                        <Button type="submit" disabled={isSubmitting}>
                            {t('Global_Validate')}
                        </Button>
                    </form>
                )}
            </Formik>
            <ErrorModal opened={sent} close={toggleSent}>
                {t('Arbox_Success_Account_Updated')}
            </ErrorModal>
            <ErrorModal opened={error} close={toggle}>
                {t('ErrorMessage_ConnectionError')}
            </ErrorModal>
        </main>
    );
};

export default styled(ChgPassword)`
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 auto;
    box-shadow: 0 0 15px rgba(28, 53, 150, 0.1);
    border-radius: 8px;

    .form {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 35px;
        border-bottom: 1px solid #dee7ef;
    }

    .title {
        color: ${({ theme }) => theme.blackTitle};
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .user_info_input {
        padding: 20px 10px;
        border-radius: 5px;
        background-color: white;
        border: 1px solid #dee7ef;
        width: 100%;
        margin-top: 15px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;

        &::placeholder {
            font-weight: 400;
            color: #7787a8;
            opacity: 0.65;
        }
    }

    .error {
        color: red;
    }
`;
