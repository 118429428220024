import React, { useState, useEffect } from 'react';
import NoData from 'components/NoData';
import useQuery from 'hook/useQuery';
import ErrorModal from 'components/modal/ErrorModal';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loading';
import styled from 'styled-components';
import Chevron from 'static/img/list-chevron.png';
import SearchIcon from 'static/img/search.svg';
import { Link } from '@reach/router';
import Toolbar from 'components/Toolbar';
import Ariane from 'components/Ariane';
import ListTitle from 'components/ListTitle';
import HeaderList from 'components/HeaderList';
import TableList from 'components/TableList';
import Fuse from 'fuse.js';
import Search from 'components/Search';
import { useGetProjectAreasQuery, AreaFragment } from 'generated/graphql';

const options = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [
        'properties.ID_UNIQUE',
        'properties.SURFACE',
        'properties.NUMERO_COMMERCIAL_LOT',
        'properties.TYPE_LOT',
        'properties.ETAGE',
        'properties.CHANTIER',
        'properties.BAT',
        'properties.ADRESSE',
        'properties.CP_VILLE',
        'properties.name',
        'properties.type',
        'properties.surface',
        'properties.level',
        'properties.TEL',
        'properties.NOM',
        'actors.properties.firstname',
        'actors.properties.lastname'
    ]
};

const filterAreas = (areas: AreaFragment[]): AreaFragment[] =>
    areas.filter(({ reports }) => reports && reports.length > 0);

const Areas: React.FC<{ path?: string; projectId?: string; className?: string }> = ({ projectId = '', className }) => {
    const [error, setError] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');

    const { data, loading, error: queryError } = useQuery(useGetProjectAreasQuery, { variables: { projectId } });

    const fuse = new Fuse(filterAreas(data?.project?.areas || []), options);

    const areas = search
        ? fuse.search<AreaFragment>(search).map(({ item }) => item)
        : filterAreas(data?.project?.areas || []);

    useEffect(() => setError(!!queryError), [queryError]);

    const [t] = useTranslation();

    const toggle = () => setError(!error);

    return (
        <>
            <div className={className}>
                <Toolbar>
                    <Ariane
                        previous={[{ url: '/', label: 'Projets' }]}
                        current={`${data && data.project ? `${data.project.properties.name} : Logements` : ''}`}
                    />
                    <Search
                        type="text"
                        placeholder="Rechercher"
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                    />
                </Toolbar>

                {loading && <Loader />}
                {areas && areas.length > 0 ? (
                    <>
                        <ListTitle>Logements</ListTitle>
                        <div className="logement">
                            <HeaderList>
                                <div className="col-main">Numéro commercial</div>
                                <div className="col-sec">Adresse</div>
                                <div className="col-sec">Type</div>
                                <div className="col-sec">Acquereur</div>
                                <div className="col-main">Nb de remarques</div>
                                <div className="col-next" />
                            </HeaderList>

                            <TableList>
                                {areas.map(area => (
                                    <Link key={area._id} to={`/project/${projectId}/area/${area._id}`} className="row">
                                        <div className="col-main">
                                            <p>
                                                {`${
                                                    area.properties.NUMERO_COMMERCIAL_LOT
                                                        ? `${area.properties.NUMERO_COMMERCIAL_LOT}`
                                                        : ''
                                                } ${
                                                    area.properties.ID_UNIQUE ? ` - ${area.properties.ID_UNIQUE}` : ''
                                                }`}
                                            </p>
                                        </div>
                                        <div className="col-sec">
                                            <p>{`${area.properties.CHANTIER || ''}${
                                                area.properties.BAT ? ` - BAT : ${area.properties.BAT}` : ''
                                            }`}</p>
                                            <p>{`${area.properties.ADRESSE || ''} ${area.properties.CP_VILLE ||
                                                ''}`}</p>
                                        </div>
                                        <div className="col-sec">
                                            <p>{`${area.properties.TYPE_LOT || area.properties.type || ''} - ${area
                                                .properties.SURFACE ||
                                                area.properties.surface ||
                                                ''}m2`}</p>

                                            <p>{`Niveau : ${area.properties.ETAGE || area.properties.level || ''}`}</p>
                                        </div>
                                        <div className="col-sec">
                                            {area.properties.NOM
                                                ? `${area.properties.NOM || ''} ${area.properties.TEL || ''}`.trim()
                                                : area.actors?.map(actor => (
                                                      <span key={actor._id}>
                                                          {`${
                                                              actor.properties.firstname
                                                                  ? actor.properties.firstname
                                                                  : ''
                                                          } ${
                                                              actor.properties.lastname ? actor.properties.lastname : ''
                                                          }`.trim()}
                                                      </span>
                                                  ))}
                                        </div>
                                        <div className="col-main">
                                            {area.reports
                                                ? area.reports.reduce((acc: number, val) => {
                                                      return acc + (val.remarks?.length || 0);
                                                  }, 0)
                                                : 0}
                                        </div>
                                        <div className="col-next">
                                            <img className="chevron" src={Chevron} alt="chevron" />
                                        </div>
                                    </Link>
                                ))}
                            </TableList>
                        </div>
                    </>
                ) : (
                    !loading && <NoData />
                )}
            </div>
            <ErrorModal opened={error} close={toggle}>
                {t('ErrorMessage_ErrorMessage')}
            </ErrorModal>
        </>
    );
};

export default styled(Areas)`
    .chevron {
        width: 8px;
    }

    .logement {
        display: flex;
        flex-direction: column;
    }

    .search_form {
        display: flex;
        margin-bottom: 30px;

        input {
            border-radius: 4px;
            border: 1px solid #e3e7f0;
            padding: 10px 15px;
            outline: 0;
            transition-duration: 0.3s;

            background: url(${SearchIcon}) no-repeat scroll 12px 9px;
            padding-left: 40px;

            font-size: 14px;
            color: ${({ theme }) => theme.blackTitle};

            &::placeholder {
                font-size: 14px;
                color: #a8b2c7;
            }

            &:hover,
            &:focus {
                box-shadow: var(--main_shadow);
            }
        }
    }
`;
