import React from 'react';
import Header from './Header';
import { Router } from '@reach/router';

import Layout from '../layouts/ProjectLayout';

import Project from '../pages/Project';
import Document from '../pages/Document';
import Area from '../pages/Area';
import Remark from '../pages/Remark';
import ChgPassword from 'pages/ChgPassword';

const AppRouter: React.FC = () => {
    return (
        <>
            <Header />
            <Router>
                <Project path="/" />
                <Layout path="/project/:projectId">
                    <Area path="/" />
                    <Remark path="/area/:areaId" />
                    <Document path="/documents" />
                </Layout>
                <ChgPassword path="/user" />
            </Router>
        </>
    );
};

export default AppRouter;
