import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
    URL: any;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: any;
    /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
    EmailAddress: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type Account = {
    __typename?: 'Account';
    _id: Scalars['ID'];
    image?: Maybe<S3Document>;
    properties: AccountProperties;
};

export type AccountInput = {
    image?: Maybe<S3DocumentInput>;
    properties: AccountPropertiesInput;
};

export type AccountProperties = {
    __typename?: 'AccountProperties';
    firstname?: Maybe<Scalars['String']>;
    lastname?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    language?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    function?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    company_type_list?: Maybe<Array<CompanyTypeDico>>;
};

export type AccountPropertiesInput = {
    firstname?: Maybe<Scalars['String']>;
    lastname?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    language?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    function?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    company_type_list?: Maybe<Array<CompanyTypeDicoInput>>;
};

export type Actor = {
    __typename?: 'Actor';
    /** Identifier of the actor. */
    _id: Scalars['ID'];
    /** Properties of the actor. */
    properties: ActorProperties;
    /** Picture of the actor */
    image?: Maybe<S3Document>;
};

export type ActorInput = {
    /** Identifier of the actor. */
    _id?: Maybe<Scalars['ID']>;
    /** Properties of the actor. */
    properties: ActorInputProperties;
};

export type ActorInputProperties = {
    /** Actor's firstname. */
    firstname: Scalars['String'];
    /** Actor's lastname. */
    lastname: Scalars['String'];
    /** Company in which the actor is working. */
    company: Scalars['String'];
    /** Type of company the actor is working in represented as an object */
    companyTypeDico?: Maybe<CompanyTypeDicoInput>;
    /** Function of the actor inside the company. */
    fonction?: Maybe<Scalars['String']>;
    /** Department in which the actor is working. */
    department?: Maybe<Scalars['String']>;
    /**
     * Type of company the actor is working in (for example 0 = Project management, 1
     * = Contractor participant, 2 = Control office, 3 = Project management, 4 =
     * Project ownership, 5 = Buyers ).
     */
    companyType: Scalars['Int'];
    /** Actor's address. */
    address?: Maybe<Scalars['String']>;
    /** Actor's address zipcode. */
    zipcode?: Maybe<Scalars['String']>;
    /** Actor's address town. */
    town?: Maybe<Scalars['String']>;
    /** Actor's address state. */
    state?: Maybe<Scalars['String']>;
    /** Actor's address country. */
    country?: Maybe<Scalars['String']>;
    /** Actor's phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Actor's mobile phone number. */
    mobile?: Maybe<Scalars['String']>;
    /** Actor's fax number. */
    fax?: Maybe<Scalars['String']>;
    /** Actor's email address */
    email: Scalars['String'];
    /** Actor's other email address. */
    otherEmail?: Maybe<Scalars['String']>;
    /** Other informations about the actor. */
    description?: Maybe<Scalars['String']>;
    /** Is this actor to be put in the address book? */
    address_book?: Maybe<Scalars['Boolean']>;
};

export type ActorProperties = {
    __typename?: 'ActorProperties';
    /** Actor's firstname. */
    firstname?: Maybe<Scalars['String']>;
    /** Actor's lastname. */
    lastname?: Maybe<Scalars['String']>;
    /** Company in which the actor is working. */
    company?: Maybe<Scalars['String']>;
    /** Function of the actor inside the company. */
    fonction?: Maybe<Scalars['String']>;
    /** Department in which the actor is working. */
    department?: Maybe<Scalars['String']>;
    /**
     * Type of company the actor is working in (for example 0 = Project management, 1
     * = Contractor participant, 2 = Control office, 3 = Project management, 4 =
     * Project ownership, 5 = Buyers ).
     */
    companyType: Scalars['Int'];
    /** Type of company the actor is working in represented as an object */
    companyTypeDico?: Maybe<CompanyTypeDico>;
    /** Actor's address. */
    address?: Maybe<Scalars['String']>;
    /** Actor's address zipcode. */
    zipcode?: Maybe<Scalars['String']>;
    /** Actor's address town. */
    town?: Maybe<Scalars['String']>;
    /** Actor's address state. */
    state?: Maybe<Scalars['String']>;
    /** Actor's address country. */
    country?: Maybe<Scalars['String']>;
    /** Actor's phone number. */
    phone?: Maybe<Scalars['String']>;
    /** Actor's mobile phone number. */
    mobile?: Maybe<Scalars['String']>;
    /** Actor's fax number. */
    fax?: Maybe<Scalars['String']>;
    /** Actor's email address */
    email?: Maybe<Scalars['String']>;
    /** Actor's other email address. */
    otherEmail?: Maybe<Scalars['String']>;
    /** Other informations about the actor. */
    description?: Maybe<Scalars['String']>;
    /** Is this actor to be put in the address book? */
    address_book?: Maybe<Scalars['Boolean']>;
};

export type Area = {
    __typename?: 'Area';
    _id: Scalars['ID'];
    properties: AreaProperties;
    reports?: Maybe<Array<Report>>;
    actors?: Maybe<Array<Actor>>;
};

export type AreaProperties = {
    __typename?: 'AreaProperties';
    SURFACE?: Maybe<Scalars['String']>;
    NUMERO_COMMERCIAL_LOT?: Maybe<Scalars['String']>;
    ID_UNIQUE?: Maybe<Scalars['String']>;
    TYPE_LOT?: Maybe<Scalars['String']>;
    ETAGE?: Maybe<Scalars['String']>;
    CHANTIER?: Maybe<Scalars['String']>;
    BAT?: Maybe<Scalars['String']>;
    ADRESSE?: Maybe<Scalars['String']>;
    CP_VILLE?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    surface?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    NOM?: Maybe<Scalars['String']>;
    TEL?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
    Public = 'PUBLIC',
    Private = 'PRIVATE'
}

export type CheckItem = {
    __typename?: 'CheckItem';
    /** Checklist's item title */
    title?: Maybe<Scalars['String']>;
    /** Checklist's item state (done=true/to-do=false) */
    state?: Maybe<Scalars['Boolean']>;
};

export type CheckItemInput = {
    title: Scalars['String'];
    state: Scalars['Boolean'];
};

export type CheckList = {
    __typename?: 'CheckList';
    _id: Scalars['ID'];
    properties: CheckListProperties;
};

export type CheckListInput = {
    _id: Scalars['ID'];
    properties: CheckListPropertiesInput;
};

export type CheckListProperties = {
    __typename?: 'CheckListProperties';
    /** Checklist's title */
    title?: Maybe<Scalars['String']>;
    data: Array<CheckItem>;
};

export type CheckListPropertiesInput = {
    title: Scalars['String'];
    data: Array<CheckItemInput>;
};

export type CompanyTypeDico = {
    __typename?: 'CompanyTypeDico';
    /** ID of the companyType (UUID v4) */
    _id?: Maybe<Scalars['String']>;
    /** Name of the companyType */
    name: Scalars['String'];
    /** Name of the companyType as displayed in the PDF report */
    export_name: Scalars['String'];
    /** Color of the companyType as an hexacode without # */
    color?: Maybe<Scalars['String']>;
    /**
     * Number of company type the actor is working in (for example 0 = Project
     * management, 1 = Contractor participant, 2 = Control office, 3 = Project
     * management, 4 = Project ownership, 5 = Buyers ). Should be over 128 for
     * account custom companytype
     */
    number: Scalars['Int'];
};

export type CompanyTypeDicoInput = {
    /** ID of the companyType (UUID v4) */
    _id?: Maybe<Scalars['String']>;
    /** Name of the companyType */
    name: Scalars['String'];
    /** Name of the companyType as displayed in the PDF report */
    export_name: Scalars['String'];
    /** Color of the companyType as an hexacode without # */
    color?: Maybe<Scalars['String']>;
    /**
     * Number of company type the actor is working in (for example 0 = Project
     * management, 1 = Contractor participant, 2 = Control office, 3 = Project
     * management, 4 = Project ownership, 5 = Buyers ). Should be over 128 for
     * account custom companytype
     */
    number: Scalars['Int'];
};

export type CustomProperty = {
    __typename?: 'CustomProperty';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type CustomPropertyInput = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type Directory = {
    __typename?: 'Directory';
    _id: Scalars['ID'];
    properties: DirectoryProperties;
    parentsDirectories?: Maybe<Array<Directory>>;
    childrenDirectories?: Maybe<Array<Directory>>;
    projects?: Maybe<Array<Project>>;
    info?: Maybe<Info>;
};

export type DirectoryChanges = {
    __typename?: 'DirectoryChanges';
    created?: Maybe<Array<Directory>>;
    updated?: Maybe<Array<Directory>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type DirectoryInput = {
    _id: Scalars['ID'];
    properties: DirectoryInputProperties;
};

export type DirectoryInputChange = {
    _id: Scalars['ID'];
    parentId?: Maybe<Scalars['ID']>;
    properties: DirectoryInputProperties;
};

export type DirectoryInputChanges = {
    created?: Maybe<Array<DirectoryInputChange>>;
    updated?: Maybe<Array<DirectoryInputChange>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type DirectoryInputProperties = {
    name: Scalars['String'];
};

export type DirectoryProperties = {
    __typename?: 'DirectoryProperties';
    name: Scalars['String'];
};

export type DocumentFolderInput = {
    properties: ProjectDocumentsPropertiesInput;
};

export type FileOrFolderInput = {
    _id: Scalars['ID'];
    properties: ProjectDocumentsPropertiesInput;
};

export type FormProperty = {
    __typename?: 'FormProperty';
    title?: Maybe<Scalars['String']>;
    type: FormPropertyType;
    items?: Maybe<Array<FormPropertyItem>>;
};

export type FormPropertyItem = {
    __typename?: 'FormPropertyItem';
    key: Scalars['String'];
    label: Scalars['String'];
    placeholder?: Maybe<Scalars['String']>;
    required?: Maybe<Scalars['Boolean']>;
    keyboard?: Maybe<Scalars['String']>;
    type?: Maybe<FormPropertyItemType>;
    creation?: Maybe<PropertyItemMode>;
    edition?: Maybe<PropertyItemMode>;
    values?: Maybe<Array<FormPropertyItemSelectorValue>>;
    default?: Maybe<Scalars['String']>;
    filterCompanyType?: Maybe<Scalars['Int']>;
};

export type FormPropertyItemSelectorValue = {
    __typename?: 'FormPropertyItemSelectorValue';
    name: Scalars['String'];
    value: Scalars['String'];
    default?: Maybe<Scalars['Boolean']>;
};

export enum FormPropertyItemType {
    LongText = 'longText',
    ShortText = 'shortText',
    Image = 'image',
    Date = 'date',
    Models = 'models',
    Selector = 'selector',
    DateAndTime = 'dateAndTime'
}

export enum FormPropertyType {
    ImportData = 'importData',
    Templates = 'templates',
    Items = 'items'
}

/** Gantt's information object */
export type GanttLink = {
    __typename?: 'GanttLink';
    /** Link's uuid */
    id: Scalars['ID'];
    /** Link's work source ID */
    source: Scalars['ID'];
    /** Link's work target ID */
    target: Scalars['ID'];
    /** Type of Gantt link */
    type: GanttLinkType;
    /** Number of days between source and target (for instance 12 or -4) */
    lag: Scalars['Int'];
};

/** Gantt's information object */
export type GanttLinkInput = {
    /** Link's uuid */
    id: Scalars['ID'];
    /** Link's work source ID */
    source: Scalars['ID'];
    /** Link's work target ID */
    target: Scalars['ID'];
    /** Type of Gantt link */
    type: GanttLinkType;
    /** Number of days between source and target (for instance 12 or -4) */
    lag: Scalars['Int'];
};

/** Type of Gantt link */
export enum GanttLinkType {
    FinishToStart = 'FINISH_TO_START',
    StartToStart = 'START_TO_START',
    FinishToFinish = 'FINISH_TO_FINISH',
    StartToFinish = 'START_TO_FINISH'
}

export type Group = {
    __typename?: 'Group';
    /** identifier of the group */
    _id: Scalars['ID'];
    /** Revision of the document */
    _rev: Scalars['String'];
    /** Properties of the group */
    properties: GroupProperties;
    /** Report templates associated to this group */
    allowedReportTemplates?: Maybe<Array<ReportTemplate>>;
    right: Scalars['String'];
    project_creation_data?: Maybe<S3Document>;
};

export type GroupChanges = {
    __typename?: 'GroupChanges';
    created?: Maybe<Array<Group>>;
    updated?: Maybe<Array<Group>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type GroupProperties = {
    __typename?: 'GroupProperties';
    /** Name of the group */
    name: Scalars['String'];
    project_properties?: Maybe<Array<FormProperty>>;
    area_properties?: Maybe<Array<FormProperty>>;
};

export type Info = {
    __typename?: 'Info';
    /** Id of the account which created this item */
    user_create_id?: Maybe<Scalars['String']>;
    creation_date?: Maybe<Scalars['String']>;
    user_create_name?: Maybe<Scalars['String']>;
    edit_date?: Maybe<Scalars['String']>;
    user_edit_id?: Maybe<Scalars['String']>;
    user_edit_name?: Maybe<Scalars['String']>;
    app_version?: Maybe<Scalars['String']>;
    app_type?: Maybe<Scalars['String']>;
};

export type LiveProject = {
    __typename?: 'LiveProject';
    _id: Scalars['ID'];
    properties: LiveProjectProperties;
    image?: Maybe<S3Document>;
    messages?: Maybe<Array<Message>>;
    works?: Maybe<Array<Work>>;
    remarks?: Maybe<Array<Remark>>;
    documents?: Maybe<ProjectDocuments>;
};

export type LiveProjectProperties = {
    __typename?: 'LiveProjectProperties';
    name: Scalars['String'];
};

export type Localization = {
    __typename?: 'Localization';
    name?: Maybe<Scalars['String']>;
};

export type LoginResult = {
    __typename?: 'LoginResult';
    token: Scalars['String'];
    account: Account;
};

export type Message = {
    __typename?: 'Message';
    _id: Scalars['ID'];
    date: Scalars['String'];
    subject?: Maybe<Scalars['String']>;
    data?: Maybe<MessageData>;
    message?: Maybe<Scalars['String']>;
    sender?: Maybe<Sender>;
};

export type MessageData = {
    __typename?: 'MessageData';
    reports?: Maybe<Array<S3LightDocument>>;
    attachedFiles?: Maybe<Array<S3Document>>;
};

export type MoveProjectOrDirectoryResponse = {
    __typename?: 'MoveProjectOrDirectoryResponse';
    root?: Maybe<RootDirectory>;
    children: Array<Directory>;
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Validate remark or validate it (through Ar.Live) */
    addObservationOrValidateRemark?: Maybe<Remark>;
    /** Rollback last status of remark (cancel it) */
    removeRemarkValidation?: Maybe<Remark>;
    /** deprecated - for backward compatibility with arlive mobile 1.1.0 */
    validateRemark?: Maybe<Remark>;
    /** login to archireport */
    login: LoginResult;
    /** Add a sub directory */
    addDirectory: Directory;
    /** Create a project */
    addProject: Project;
    /** Build an object to store with a signed url */
    upload: UploadResponse;
    /** Add reports to existing projects (actors, works and documents are preset with project actors, works and plans) */
    addReports: Array<Report>;
    /** Add remarks to existing reports */
    addRemarks: Array<Remark>;
    /** Delete project */
    deleteProject: Project;
    /** Create an actor */
    addActor: Actor;
    /** Edit an actor */
    modifyActor: Actor;
    /** Delete Actor */
    deleteActor: Actor;
    /** Modify project */
    modifyProject: Project;
    /** Create from sample */
    createFromSample: Project;
    /** Clone project */
    cloneProject?: Maybe<Project>;
    /** Set archive status (when archived the project is no more synced) */
    setArchivedStatus: Project;
    /** Move project into another folder (root by default) */
    moveProject?: Maybe<MoveProjectOrDirectoryResponse>;
    /** Add picture to project. */
    addPictureToProject: Project;
    /** Move report in the list of project reports. */
    moveReport: Project;
    /** Move all reports in project (all missing reports are lost). */
    moveReports: Project;
    /** Delete report. */
    deleteReport: Report;
    /** Modify directory name */
    modifyDirectory: Directory;
    /** Delete directory */
    deleteDirectory: Directory;
    /** Move directory into another folder (root by default) */
    moveDirectory?: Maybe<MoveProjectOrDirectoryResponse>;
    /** Create work in project */
    addWork: Work;
    /** Create works structure in project */
    addWorks: Array<Work>;
    /** Modify work */
    modifyWork: Work;
    /** Delete work */
    deleteWork: Work;
    /** Grant access to project */
    shareProject: Project;
    /** Revoke access to project */
    revokeAccessToProject: Project;
    /** Create Document SubFolder */
    addDocumentFolder: ProjectDocuments;
    /** Add File into existing folder */
    addFileToDocumentFolder: ProjectDocuments;
    /** Remove file or folder */
    deleteFileFromDocumentFolder: ProjectDocuments;
    /** Modify file or folder */
    modifyFileInDocumentFolder: ProjectDocuments;
    /** Modify project to add actors to it */
    addActorsInProject: Project;
    /** Modify project to remove actors from it */
    removeActorsFromProject: Project;
    /** Modify report to add actors to it */
    addActorsInReport: Report;
    /** Modify report to remove actors from it */
    removeActorsFromReport: Report;
    /** Add Works (add workIds in report) and show subworks (remove hideSubWorks in report) in report */
    addWorksInReport: Report;
    /** Remove Works (remove ids from report) and  hide subWorks (add ids in report) in report */
    removeWorksInReport: Report;
    /** Modify account properties and picture */
    modifyAccount: Account;
    /** Modify account password */
    modifyAccountPassword: Account;
    /** Add actors in work or subwork */
    addActorsInWork: Work;
    /** Remove actors from work or subwork */
    removeActorsFromWork: Work;
    /** Add plans into project's plans folder (plans have to be PDF if not there won't be added) */
    addPlansToProject: ProjectDocuments;
    /** Push directory changes (creations / modifications / deletions) */
    pushDirectoryChanges: RootDirectory;
    /** Push project changes (creations / modifications / deletions) */
    pushProjectChanges: Array<Project>;
    /** Add pictures to projects. */
    addPicturesToProjects: Array<Project>;
    /** Push report changes (creations / modifications / deletions) */
    pushReportChanges: Array<Report>;
    /** Push remark changes (creations / modifications / deletions) */
    pushRemarkChanges: Array<Remark>;
};

export type MutationAddObservationOrValidateRemarkArgs = {
    status: ObservationRemarkInput;
};

export type MutationRemoveRemarkValidationArgs = {
    remarkId: Scalars['ID'];
};

export type MutationValidateRemarkArgs = {
    status: ValidateRemarkInput;
};

export type MutationLoginArgs = {
    email: Scalars['EmailAddress'];
    password: Scalars['String'];
};

export type MutationAddDirectoryArgs = {
    directory: DirectoryInput;
    parentId?: Maybe<Scalars['ID']>;
};

export type MutationAddProjectArgs = {
    project: ProjectInput;
    parentDirectoryId?: Maybe<Scalars['ID']>;
};

export type MutationUploadArgs = {
    params: UploadParams;
};

export type MutationAddReportsArgs = {
    listReports: Array<ReportInput>;
};

export type MutationAddRemarksArgs = {
    listRemarks: Array<RemarkInput>;
};

export type MutationDeleteProjectArgs = {
    projectId: Scalars['ID'];
};

export type MutationAddActorArgs = {
    actor: ActorInput;
    projectId?: Maybe<Scalars['ID']>;
};

export type MutationModifyActorArgs = {
    actor: ActorInput;
    projectId?: Maybe<Scalars['ID']>;
};

export type MutationDeleteActorArgs = {
    actor_id: Scalars['ID'];
};

export type MutationModifyProjectArgs = {
    project: ProjectModifyInput;
};

export type MutationCreateFromSampleArgs = {
    lang: Scalars['String'];
    idSample?: Maybe<Scalars['String']>;
};

export type MutationCloneProjectArgs = {
    projectId: Scalars['String'];
};

export type MutationSetArchivedStatusArgs = {
    projectId: Scalars['String'];
    archived: Scalars['Boolean'];
};

export type MutationMoveProjectArgs = {
    projectId: Scalars['ID'];
    targetDirectoryId?: Maybe<Scalars['ID']>;
};

export type MutationAddPictureToProjectArgs = {
    projectId: Scalars['ID'];
    image: S3DocumentInput;
};

export type MutationMoveReportArgs = {
    reportId: Scalars['ID'];
    targetPosition: Scalars['Int'];
};

export type MutationMoveReportsArgs = {
    projectId: Scalars['ID'];
    listReportIds: Array<Scalars['ID']>;
};

export type MutationDeleteReportArgs = {
    reportId: Scalars['ID'];
};

export type MutationModifyDirectoryArgs = {
    directory: DirectoryInput;
};

export type MutationDeleteDirectoryArgs = {
    directoryId: Scalars['ID'];
};

export type MutationMoveDirectoryArgs = {
    directoryId: Scalars['ID'];
    targetDirectoryId?: Maybe<Scalars['ID']>;
};

export type MutationAddWorkArgs = {
    work: WorkInput;
    projectId: Scalars['ID'];
};

export type MutationAddWorksArgs = {
    works: Array<WorkInputStructure>;
    projectId: Scalars['ID'];
};

export type MutationModifyWorkArgs = {
    work: WorkUpdateInput;
};

export type MutationDeleteWorkArgs = {
    workId: Scalars['ID'];
};

export type MutationShareProjectArgs = {
    projectId: Scalars['ID'];
    emailOfGrantedUser: Scalars['EmailAddress'];
    permission: Array<Permission>;
};

export type MutationRevokeAccessToProjectArgs = {
    projectId: Scalars['ID'];
    emailOfRevokedUser: Scalars['EmailAddress'];
};

export type MutationAddDocumentFolderArgs = {
    parent: Scalars['ID'];
    folder: DocumentFolderInput;
};

export type MutationAddFileToDocumentFolderArgs = {
    parent: Scalars['ID'];
    file: S3DocumentInput;
};

export type MutationDeleteFileFromDocumentFolderArgs = {
    fileOrFolderId: Scalars['ID'];
};

export type MutationModifyFileInDocumentFolderArgs = {
    fileOrFolder: FileOrFolderInput;
};

export type MutationAddActorsInProjectArgs = {
    projectId: Scalars['ID'];
    actorsId: Array<Scalars['ID']>;
};

export type MutationRemoveActorsFromProjectArgs = {
    projectId: Scalars['ID'];
    actorsId: Array<Scalars['ID']>;
};

export type MutationAddActorsInReportArgs = {
    reportId: Scalars['ID'];
    actorsId: Array<Scalars['ID']>;
};

export type MutationRemoveActorsFromReportArgs = {
    reportId: Scalars['ID'];
    actorsId: Array<Scalars['ID']>;
    deleteRemarks?: Maybe<Scalars['Boolean']>;
};

export type MutationAddWorksInReportArgs = {
    reportId: Scalars['ID'];
    worksId: Array<Scalars['ID']>;
    hideSubWorksId?: Maybe<Array<Scalars['ID']>>;
};

export type MutationRemoveWorksInReportArgs = {
    reportId: Scalars['ID'];
    worksId: Array<Scalars['ID']>;
    hideSubWorksId?: Maybe<Array<Scalars['ID']>>;
    deleteRemarks?: Maybe<Scalars['Boolean']>;
};

export type MutationModifyAccountArgs = {
    account: AccountInput;
};

export type MutationModifyAccountPasswordArgs = {
    oldPassword: Scalars['String'];
    newPassword: Scalars['String'];
};

export type MutationAddActorsInWorkArgs = {
    projectId: Scalars['ID'];
    workId: Scalars['ID'];
    actorsId: Array<Scalars['ID']>;
};

export type MutationRemoveActorsFromWorkArgs = {
    projectId: Scalars['ID'];
    workId: Scalars['ID'];
    actorsId: Array<Scalars['ID']>;
};

export type MutationAddPlansToProjectArgs = {
    projectId: Scalars['ID'];
    plans: Array<S3DocumentInput>;
};

export type MutationPushDirectoryChangesArgs = {
    changes: DirectoryInputChanges;
};

export type MutationPushProjectChangesArgs = {
    changes: ProjectInputChanges;
};

export type MutationAddPicturesToProjectsArgs = {
    pictures: Array<ProjectPictureInput>;
};

export type MutationPushReportChangesArgs = {
    changes: ReportInputChanges;
};

export type MutationPushRemarkChangesArgs = {
    changes: RemarkInputChanges;
};

export type Observation = {
    __typename?: 'Observation';
    _id: Scalars['ID'];
    properties: ObservationProperties;
    info?: Maybe<Info>;
};

export type ObservationInput = {
    _id: Scalars['ID'];
    properties: ObservationInputProperties;
};

export type ObservationInputProperties = {
    message: Scalars['String'];
};

export type ObservationProperties = {
    __typename?: 'ObservationProperties';
    /** Observation's text (comment) */
    message: Scalars['String'];
};

export type ObservationRemarkInput = {
    remarkId: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
    picture?: Maybe<S3DocumentInput>;
    validate?: Maybe<Scalars['Boolean']>;
};

export enum Order_By {
    /** Alphabetical Ascendent */
    AlphaAsc = 'alpha_ASC',
    /** Alphabetical Descendent */
    AlphaDesc = 'alpha_DESC',
    /** Creation date Ascendent */
    CreationAsc = 'creation_ASC',
    /** Creation date Descendent */
    CreationDesc = 'creation_DESC'
}

export type ParentDirectory = {
    __typename?: 'ParentDirectory';
    _id: Scalars['ID'];
    properties: DirectoryProperties;
    childrenDirectories?: Maybe<Array<Directory>>;
    projects: Array<Project>;
    info?: Maybe<Info>;
};

export type Part = {
    __typename?: 'Part';
    _id: Scalars['ID'];
    parent_id?: Maybe<Scalars['ID']>;
    properties: PartProperties;
};

export type PartInput = {
    _id: Scalars['ID'];
    parent_id?: Maybe<Scalars['ID']>;
    properties?: Maybe<PartInputProperties>;
};

export type PartInputProperties = {
    name: Scalars['String'];
    remark_type_key?: Maybe<Scalars['String']>;
};

export type PartProperties = {
    __typename?: 'PartProperties';
    name: Scalars['String'];
    remark_type_key?: Maybe<Scalars['String']>;
};

export enum Permission {
    Read = 'READ',
    Write = 'WRITE',
    /** User will have the ability to reshare this project */
    Share = 'SHARE'
}

export type Position = {
    __typename?: 'Position';
    _id: Scalars['ID'];
    /** Plan on which is located the remark */
    document?: Maybe<S3Document>;
    properties: PositionProperties;
};

export type PositionProperties = {
    __typename?: 'PositionProperties';
    /** Abscissa position of the remark on plan (relative to the whole dimension of the PDF doc and not located in the page) */
    x: Scalars['String'];
    /** Ordinate position of the remark on plan (relative to the whole dimension of the PDF doc and not located in the page) */
    y: Scalars['String'];
};

export type Project = {
    __typename?: 'Project';
    /** Identifier of the project. */
    _id: Scalars['ID'];
    /** Properties of the project. */
    properties: ProjectProperties;
    /** Custom properties (defined in your group). */
    customProperties?: Maybe<Array<CustomProperty>>;
    /** Picture of the project */
    image?: Maybe<S3Document>;
    /** Area of the project. */
    areas?: Maybe<Array<Area>>;
    /** Actors of the project. */
    actors?: Maybe<Array<Actor>>;
    /** Works of the project. */
    works?: Maybe<Array<Work>>;
    /** Documents associated to the project (plans ...). */
    documents?: Maybe<ProjectDocuments>;
    /** Reports associated to the project. */
    reports?: Maybe<Array<Report>>;
    /** Update infos of the project. */
    info?: Maybe<Info>;
    /** Directory */
    directory?: Maybe<Directory>;
};

export enum Project_Type {
    Mine = 'MINE',
    SharedViaSubgroups = 'SHARED_VIA_SUBGROUPS'
}

export type ProjectChanges = {
    __typename?: 'ProjectChanges';
    created?: Maybe<Array<Project>>;
    updated?: Maybe<Array<Project>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type ProjectCreationInput = {
    project: ProjectInput;
    parentDirectory?: Maybe<Scalars['ID']>;
};

export type ProjectDocuments = {
    __typename?: 'ProjectDocuments';
    _id: Scalars['ID'];
    properties?: Maybe<S3DocumentProperties>;
    file_type: Scalars['String'];
    parents?: Maybe<Array<Maybe<Scalars['ID']>>>;
    root?: Maybe<Scalars['ID']>;
    info?: Maybe<Info>;
    files?: Maybe<Array<ProjectDocuments>>;
    s3_name?: Maybe<Scalars['String']>;
    s3_bucket?: Maybe<Scalars['String']>;
    url?: Maybe<S3DocumentUrl>;
};

export type ProjectDocumentsPropertiesInput = {
    name: Scalars['String'];
};

export type ProjectInput = {
    /** Identifier of the project. */
    _id?: Maybe<Scalars['ID']>;
    /** Properties of the project. */
    properties: ProjectInputProperties;
    /** Custom properties (defined in your group). */
    customProperties?: Maybe<Array<CustomPropertyInput>>;
};

export type ProjectInputChanges = {
    created?: Maybe<Array<ProjectCreationInput>>;
    updated?: Maybe<Array<ProjectInput>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type ProjectInputProperties = {
    /** Name of the project. */
    name: Scalars['String'];
    /** Description of the project. */
    description?: Maybe<Scalars['String']>;
    /** Client concerned by the project. */
    client?: Maybe<Scalars['String']>;
    /** Associated report templates. */
    report_templates?: Maybe<Array<Scalars['String']>>;
    /** Start date of the project. */
    startDate?: Maybe<Scalars['DateTime']>;
    /** End date of the project. */
    endDate?: Maybe<Scalars['DateTime']>;
    /** Address (street) of the project. */
    address?: Maybe<Scalars['String']>;
    /** Postcode of the project. */
    zipcode?: Maybe<Scalars['String']>;
    /** City of the project. */
    town?: Maybe<Scalars['String']>;
    /** State of the project. */
    state?: Maybe<Scalars['String']>;
    /** Country of the project. */
    country?: Maybe<Scalars['String']>;
    /** Phone number of the Client. */
    phone?: Maybe<Scalars['String']>;
    /** mobile: String */
    mobile?: Maybe<Scalars['String']>;
    /** fax: String */
    fax?: Maybe<Scalars['String']>;
    /** email: String */
    email?: Maybe<Scalars['String']>;
    /** Other contact email of the client. */
    otherEmail?: Maybe<Scalars['String']>;
};

export type ProjectModifyInput = {
    /** Identifier of the project. */
    _id: Scalars['ID'];
    /** Properties of the project. */
    properties: ProjectInputProperties;
    /** Custom properties (defined in your group). */
    customProperties?: Maybe<Array<CustomPropertyInput>>;
    /** Custom properties (defined in your group). */
    actors: Array<Scalars['String']>;
};

export type ProjectPictureInput = {
    projectId: Scalars['ID'];
    image: S3DocumentInput;
};

export type ProjectProperties = {
    __typename?: 'ProjectProperties';
    /** Name of the project. */
    name: Scalars['String'];
    /** Description of the project. */
    description?: Maybe<Scalars['String']>;
    /** Client concerned by the project. */
    client?: Maybe<Scalars['String']>;
    /** Associated report templates. */
    report_templates?: Maybe<Array<ReportTemplate>>;
    /** Start date of the project. */
    startDate?: Maybe<Scalars['DateTime']>;
    /** End date of the project. */
    endDate?: Maybe<Scalars['DateTime']>;
    /** Address (street) of the project. */
    address?: Maybe<Scalars['String']>;
    /** Postcode of the project. */
    zipcode?: Maybe<Scalars['String']>;
    /** City of the project. */
    town?: Maybe<Scalars['String']>;
    /** State of the project. */
    state?: Maybe<Scalars['String']>;
    /** Country of the project. */
    country?: Maybe<Scalars['String']>;
    /** Phone number of the Client. */
    phone?: Maybe<Scalars['String']>;
    /** Cell number of the Client. */
    mobile?: Maybe<Scalars['String']>;
    /** Fax number of the client. */
    fax?: Maybe<Scalars['String']>;
    /** Email of the client. */
    email?: Maybe<Scalars['String']>;
    /** Other contact email of the client. */
    otherEmail?: Maybe<Scalars['String']>;
    /** True when the project is archived (no more synchronized on devices). */
    archived?: Maybe<Scalars['Boolean']>;
};

export type PropertyItemMode = {
    __typename?: 'PropertyItemMode';
    disabled?: Maybe<Scalars['Boolean']>;
};

export type Query = {
    __typename?: 'Query';
    /** Get a project with its id */
    project?: Maybe<Project>;
    /** Get an area with its id */
    area?: Maybe<Area>;
    /** Get projects */
    projects: Array<Project>;
    /** Get documents wih its path */
    document?: Maybe<ProjectDocuments>;
    /** Get projects shared via Ar.Live */
    liveProjects: Array<LiveProject>;
    /** Get project shared via Ar.Live */
    liveProject?: Maybe<LiveProject>;
    actor?: Maybe<Actor>;
    actors: Array<Actor>;
    /** Get a directory */
    directory: Directory;
    /** Get root directory */
    rootDirectory: RootDirectory;
    /** Get associated groups to connected user */
    groups: Array<Group>;
    /** Get reports */
    reports: Array<Report>;
    /** Get remarks of project, filtering by last status */
    remarks: Array<Remark>;
    /** Get works */
    works: Array<Work>;
    /** Get current logged account */
    me: Account;
    /** Get directory changes (knownDirectories helps to check whether they are still in db or not) */
    directoryChanges: DirectoryChanges;
    /** Get server date (for sync purpose) */
    serverDate: Scalars['DateTime'];
    /** Get group changes (knownGroups helps to check whether they are still in db or not) */
    groupChanges: GroupChanges;
    /** Get project changes (knownProjects helps to check whether they are still in db or not) */
    projectChanges: ProjectChanges;
    /** Get report templates changes (knownReportTemplates helps to check whether they are still in db or not) */
    reportTemplateChanges: ReportTemplateChanges;
    /** Get report changes (knownReports helps to check whether they are still in db or not) */
    reportChanges: ReportChanges;
    /** Get remark changes */
    remarkChanges: RemarkChanges;
};

export type QueryProjectArgs = {
    id: Scalars['ID'];
};

export type QueryAreaArgs = {
    id: Scalars['ID'];
};

export type QueryProjectsArgs = {
    filter: Array<Maybe<Project_Type>>;
    orderBy?: Maybe<Order_By>;
};

export type QueryDocumentArgs = {
    root: Scalars['ID'];
    path?: Maybe<Array<Scalars['ID']>>;
};

export type QueryLiveProjectArgs = {
    id: Scalars['ID'];
};

export type QueryActorArgs = {
    id: Scalars['ID'];
};

export type QueryActorsArgs = {
    project_id?: Maybe<Scalars['ID']>;
};

export type QueryDirectoryArgs = {
    id: Scalars['ID'];
};

export type QueryReportsArgs = {
    projectId: Scalars['ID'];
    reportTemplateId: Scalars['ID'];
};

export type QueryRemarksArgs = {
    projectId: Scalars['ID'];
    includeRemarkWithStatuses?: Maybe<Array<Scalars['Int']>>;
};

export type QueryWorksArgs = {
    projectId: Scalars['ID'];
};

export type QueryDirectoryChangesArgs = {
    lastSync?: Maybe<Scalars['DateTime']>;
    knownDirectories: Array<Scalars['ID']>;
};

export type QueryGroupChangesArgs = {
    lastSync?: Maybe<Scalars['DateTime']>;
    knownGroups: Array<Scalars['ID']>;
};

export type QueryProjectChangesArgs = {
    lastSync?: Maybe<Scalars['DateTime']>;
    knownProjects: Array<Scalars['ID']>;
};

export type QueryReportTemplateChangesArgs = {
    lastSync?: Maybe<Scalars['DateTime']>;
    knownReportTemplates: Array<Scalars['ID']>;
};

export type QueryReportChangesArgs = {
    lastSync?: Maybe<Scalars['DateTime']>;
    knownReports: Array<Scalars['ID']>;
};

export type QueryRemarkChangesArgs = {
    lastSync?: Maybe<Scalars['DateTime']>;
};

export type Remark = {
    __typename?: 'Remark';
    _id: Scalars['ID'];
    properties: RemarkProperties;
    /** Pictures associated to this remark */
    documents?: Maybe<Array<S3Document>>;
    /** All statuses added to the remark (chronologically) */
    status: Array<RemarkStatus>;
    /** Where is located the remark on plans */
    positions?: Maybe<Array<Position>>;
    /** Observations (comments) about this remark */
    observations?: Maybe<Array<Observation>>;
    /**
     * Works associated with this remark (deprecated)
     * @deprecated Use relatedWorks instead
     */
    works?: Maybe<Array<Scalars['ID']>>;
    /** Works associated with this remark */
    relatedWorks?: Maybe<Array<Work>>;
    /** Stakeholders associated with this remark */
    actors?: Maybe<Array<Actor>>;
    checklist?: Maybe<CheckList>;
    index: Scalars['String'];
    /** Associated report */
    reportId?: Maybe<Scalars['ID']>;
    /** Associated part */
    partId?: Maybe<Scalars['ID']>;
    info?: Maybe<Info>;
};

export type RemarkChanges = {
    __typename?: 'RemarkChanges';
    created?: Maybe<Array<Remark>>;
    updated?: Maybe<Array<Remark>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type RemarkField = {
    __typename?: 'RemarkField';
    remark_type?: Maybe<Scalars['String']>;
    key: Scalars['String'];
    type: Scalars['String'];
    order: Scalars['Int'];
    creation_remark_status?: Maybe<Scalars['Int']>;
    status_list?: Maybe<Array<Scalars['Int']>>;
};

export type RemarkInput = {
    _id?: Maybe<Scalars['ID']>;
    properties: RemarkPropertiesInput;
    checklist?: Maybe<CheckListInput>;
    report_id: Scalars['String'];
    part_id?: Maybe<Scalars['String']>;
    /** Observations (comments) about this remark */
    observations?: Maybe<Array<ObservationInput>>;
    /** All statuses added to the remark (chronologically) */
    status: Array<RemarkStatusInput>;
    /** Works associated with this remark */
    works?: Maybe<Array<Scalars['ID']>>;
    /** Stakeholders associated with this remark */
    actors?: Maybe<Array<Scalars['ID']>>;
};

export type RemarkInputChanges = {
    created?: Maybe<Array<RemarkLightInput>>;
    updated?: Maybe<Array<RemarkLightInput>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type RemarkLightInput = {
    /** UUID of the remark */
    _id: Scalars['ID'];
    /** Data of the remark */
    properties: RemarkPropertiesInput;
    /** Associated checklist */
    checklist?: Maybe<CheckListInput>;
    /** Associated report */
    report_id: Scalars['String'];
    /** Part of the report */
    part_id?: Maybe<Scalars['String']>;
    /** Observations (comments) about this remark */
    observations?: Maybe<Array<ObservationInput>>;
};

export type RemarkProperties = {
    __typename?: 'RemarkProperties';
    /** Optional remark's title only available in some report_templates */
    remark_title?: Maybe<Scalars['String']>;
    /** Remark's description available in most report_templates (default one included) */
    descriptionText?: Maybe<Scalars['String']>;
    /** Description in html format */
    description?: Maybe<Scalars['String']>;
    /** Remark's localization only available in some report_templates (promotion for instance) */
    localization?: Maybe<Array<Localization>>;
    /** Remark's copy option only available in some report_templates (possible values "last" or "first") */
    Target_copy?: Maybe<Scalars['String']>;
};

export type RemarkPropertiesInput = {
    /** Optional remark's title only available in some report_templates */
    remark_title?: Maybe<Scalars['String']>;
    /** Remark's description available in most report_templates (default one included) */
    descriptionText?: Maybe<Scalars['String']>;
    /** Remark's copy option only available in some report_templates (possible values "last" or "first") */
    Target_copy?: Maybe<Scalars['String']>;
};

export type RemarkStatus = {
    __typename?: 'RemarkStatus';
    /** Identifier of the status */
    _id: Scalars['ID'];
    /** Picture illustrating the status. */
    image?: Maybe<S3Document>;
    /** Properties of the status. */
    properties: RemarkStatusProperties;
};

export type RemarkStatusInput = {
    _id?: Maybe<Scalars['ID']>;
    properties: RemarkStatusPropertiesInput;
};

export type RemarkStatusProperties = {
    __typename?: 'RemarkStatusProperties';
    /** Value of the status. */
    status: Scalars['Int'];
    /** Date of added status. */
    date: Scalars['DateTime'];
    /** Optional comment associated to status */
    comment?: Maybe<Scalars['String']>;
};

export type RemarkStatusPropertiesInput = {
    status: Scalars['Int'];
    date: Scalars['String'];
};

export type Report = {
    __typename?: 'Report';
    /** Identifier of the report. */
    _id: Scalars['ID'];
    /** Properties of the report. */
    properties: ReportProperties;
    /** Remarks of the report */
    remarks?: Maybe<Array<Remark>>;
    /** Actors associated to report. */
    actors?: Maybe<Array<Actor>>;
    /** Picture of the reports */
    image?: Maybe<S3Document>;
    /** Creation & Modification infos */
    info?: Maybe<Info>;
    /** Used template for report */
    report_template_id?: Maybe<Scalars['String']>;
    /** Works in the report */
    works?: Maybe<Array<Work>>;
    parts?: Maybe<Array<Part>>;
    /** Id of related project */
    projectId?: Maybe<Scalars['ID']>;
};

export type ReportChanges = {
    __typename?: 'ReportChanges';
    created?: Maybe<Array<Report>>;
    updated?: Maybe<Array<Report>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

/** Configuration item of the report */
export type ReportConfig = {
    __typename?: 'ReportConfig';
    /** Unique key to store the config item */
    key: Scalars['String'];
    /** Displayed label (for GUI) */
    title: Scalars['String'];
    /** Associated properties (form inputs) */
    report_properties?: Maybe<Array<Maybe<FormProperty>>>;
};

export type ReportInput = {
    /** Unique identifier of the report */
    _id?: Maybe<Scalars['ID']>;
    properties: ReportPropertiesInput;
    /** Associated project */
    projectId: Scalars['ID'];
    /** Used report template */
    report_template_id: Scalars['String'];
    parts?: Maybe<Array<PartInput>>;
};

export type ReportInputChanges = {
    created?: Maybe<Array<ReportInput>>;
    updated?: Maybe<Array<ReportModifyInput>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

export type ReportModifyInput = {
    /** Unique identifier of the report */
    _id: Scalars['ID'];
    properties: ReportPropertiesInput;
    /** Associated project */
    projectId: Scalars['ID'];
    /** Used report template */
    report_template_id: Scalars['String'];
    parts?: Maybe<Array<PartInput>>;
};

export type ReportProperties = {
    __typename?: 'ReportProperties';
    /** Name of the report. */
    name: Scalars['String'];
    /** Number of the report. */
    number?: Maybe<Scalars['String']>;
    /** Date when the report has been made. */
    date: Scalars['DateTime'];
    /** Description of the report */
    description?: Maybe<Scalars['String']>;
    /** Report step in the project life */
    step?: Maybe<Scalars['String']>;
    /** Date of the next site inspection (ISO UTC offset date, ex. 1977-04-22T01:00:00-05:00) */
    nextReportDate?: Maybe<Scalars['DateTime']>;
};

export type ReportPropertiesInput = {
    /** Report's number */
    number: Scalars['String'];
    /** Report's name */
    name?: Maybe<Scalars['String']>;
    /** Date of site inspection (ISO UTC offset date, ex. 1977-04-22T01:00:00-05:00) */
    date?: Maybe<Scalars['DateTime']>;
    /** Short description of the report */
    description?: Maybe<Scalars['String']>;
    /** Report step in the project life */
    step?: Maybe<Scalars['String']>;
    /** Date of the next site inspection (ISO UTC offset date, ex. 1977-04-22T01:00:00-05:00) */
    nextReportDate?: Maybe<Scalars['DateTime']>;
};

/** Report template (configuration of Reports) */
export type ReportTemplate = {
    __typename?: 'ReportTemplate';
    _id: Scalars['ID'];
    properties: ReportTemplateProperties;
    import_file?: Maybe<S3Document>;
};

export type ReportTemplateChanges = {
    __typename?: 'ReportTemplateChanges';
    created?: Maybe<Array<ReportTemplate>>;
    updated?: Maybe<Array<ReportTemplate>>;
    deleted?: Maybe<Array<Scalars['ID']>>;
};

/** Properties of the report template */
export type ReportTemplateProperties = {
    __typename?: 'ReportTemplateProperties';
    name: Scalars['String'];
    remark_items: Array<RemarkField>;
    report_properties_list?: Maybe<Array<ReportConfig>>;
};

export type RootDirectory = {
    __typename?: 'RootDirectory';
    childrenDirectories?: Maybe<Array<Directory>>;
    projects?: Maybe<Array<Project>>;
};

export type S3Document = {
    __typename?: 'S3Document';
    _id: Scalars['ID'];
    url?: Maybe<S3DocumentUrl>;
    s3_bucket: Scalars['String'];
    s3_name: Scalars['String'];
    fileName?: Maybe<Scalars['String']>;
    file_type: Scalars['String'];
    properties: S3DocumentProperties;
    info?: Maybe<Info>;
};

export type S3DocumentInput = {
    _id?: Maybe<Scalars['ID']>;
    s3_name: Scalars['String'];
    s3_bucket: Scalars['String'];
    file_type: Scalars['String'];
    fileName?: Maybe<Scalars['String']>;
    properties: S3DocumentInputProperties;
    /** If file exists already should it be replaced ? If not filename will be changed */
    overwrite?: Maybe<Scalars['Boolean']>;
};

export type S3DocumentInputProperties = {
    name: Scalars['String'];
};

export type S3DocumentProperties = {
    __typename?: 'S3DocumentProperties';
    name?: Maybe<Scalars['String']>;
};

export type S3DocumentUrl = {
    __typename?: 'S3DocumentUrl';
    thumbnail: Scalars['URL'];
    original: Scalars['URL'];
};

export type S3LightDocument = {
    __typename?: 'S3LightDocument';
    url?: Maybe<S3DocumentUrl>;
    s3_bucket?: Maybe<Scalars['String']>;
    s3_name?: Maybe<Scalars['String']>;
};

export type Sender = {
    __typename?: 'Sender';
    name?: Maybe<Scalars['String']>;
    image?: Maybe<S3Document>;
};

export type UploadParams = {
    fileType: Scalars['String'];
    fileName?: Maybe<Scalars['String']>;
    _id?: Maybe<Scalars['String']>;
};

export type UploadResponse = {
    __typename?: 'UploadResponse';
    signedUploadUrl: Scalars['String'];
    objectToStore: S3Document;
};

export type ValidateRemarkInput = {
    remarkId: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
    picture?: Maybe<S3DocumentInput>;
};

export type Work = {
    __typename?: 'Work';
    /** Unique identifier of the Work */
    _id: Scalars['ID'];
    /** Actors associated to the work */
    actors?: Maybe<Array<Actor>>;
    /** Parent of the work */
    parent?: Maybe<Scalars['ID']>;
    /** Properties of the work */
    properties: WorkProperties;
    /**
     * subWorks
     * @deprecated all works are already returned from parent
     */
    subWorks?: Maybe<Array<Work>>;
};

/** Data to create a new work */
export type WorkInput = {
    /** Unique identifier of the Work (optional, generated by default) */
    _id?: Maybe<Scalars['ID']>;
    /** parent of the work (for subworks) */
    parent?: Maybe<Scalars['ID']>;
    /** properties of the work */
    properties: WorkInputProperties;
    /** Ids of actors associated to the work */
    actors?: Maybe<Array<Scalars['ID']>>;
};

/** Properties of the work */
export type WorkInputProperties = {
    /** Name of the work */
    name: Scalars['String'];
    /** Work number */
    number?: Maybe<Scalars['String']>;
    /** Description of the work */
    description?: Maybe<Scalars['String']>;
    /** Date when work starts */
    startDate?: Maybe<Scalars['DateTime']>;
    /** Date when work ends */
    endDate?: Maybe<Scalars['DateTime']>;
    /** Progress of the work (between 0 and 1) */
    progress?: Maybe<Scalars['Float']>;
    /** Gantt's information object */
    links?: Maybe<Array<GanttLinkInput>>;
};

/** Data to create new works structure */
export type WorkInputStructure = {
    /** Unique identifier of the Work (optional, generated by default) */
    _id?: Maybe<Scalars['ID']>;
    /** properties of the work */
    properties: WorkInputProperties;
    /** Ids of actors associated to the work */
    actors?: Maybe<Array<Scalars['ID']>>;
    /** subWorks */
    subWorks?: Maybe<Array<WorkInputStructure>>;
};

/** Properties of the work */
export type WorkProperties = {
    __typename?: 'WorkProperties';
    /** Name of the work */
    name?: Maybe<Scalars['String']>;
    /** Work number */
    number?: Maybe<Scalars['String']>;
    /** Description of the work */
    description?: Maybe<Scalars['String']>;
    /** Date when work starts */
    startDate?: Maybe<Scalars['DateTime']>;
    /** Date when work ends */
    endDate?: Maybe<Scalars['DateTime']>;
    /** Progress of the work (between 0 and 1) */
    progress?: Maybe<Scalars['Float']>;
    /** Gantt's information object */
    links?: Maybe<Array<Maybe<GanttLink>>>;
};

/** Data to update an existing work */
export type WorkUpdateInput = {
    /** Identifier of the work */
    _id: Scalars['ID'];
    /** properties of the work */
    properties: WorkInputProperties;
    /** Ids of actors associated to the work */
    actors?: Maybe<Array<Scalars['ID']>>;
};

export type DocumentFragment = { __typename?: 'S3Document' } & Pick<S3Document, '_id'> & {
        properties: { __typename?: 'S3DocumentProperties' } & Pick<S3DocumentProperties, 'name'>;
        info?: Maybe<{ __typename?: 'Info' } & Pick<Info, 'creation_date'>>;
        url?: Maybe<{ __typename?: 'S3DocumentUrl' } & Pick<S3DocumentUrl, 'thumbnail' | 'original'>>;
    };

export type PositionFragment = { __typename?: 'Position' } & Pick<Position, '_id'> & {
        document?: Maybe<{ __typename?: 'S3Document' } & DocumentFragment>;
        properties: { __typename?: 'PositionProperties' } & Pick<PositionProperties, 'x' | 'y'>;
    };

export type RemarkFragment = { __typename?: 'Remark' } & Pick<Remark, '_id'> & {
        properties: { __typename?: 'RemarkProperties' } & Pick<RemarkProperties, 'descriptionText'> & {
                localization?: Maybe<Array<{ __typename?: 'Localization' } & Pick<Localization, 'name'>>>;
            };
        observations?: Maybe<
            Array<
                { __typename?: 'Observation' } & Pick<Observation, '_id'> & {
                        properties: { __typename?: 'ObservationProperties' } & Pick<ObservationProperties, 'message'>;
                        info?: Maybe<{ __typename?: 'Info' } & Pick<Info, 'user_create_name' | 'edit_date'>>;
                    }
            >
        >;
        documents?: Maybe<Array<{ __typename?: 'S3Document' } & DocumentFragment>>;
        status: Array<
            { __typename?: 'RemarkStatus' } & {
                properties: { __typename?: 'RemarkStatusProperties' } & Pick<RemarkStatusProperties, 'status' | 'date'>;
                image?: Maybe<{ __typename?: 'S3Document' } & DocumentFragment>;
            }
        >;
        positions?: Maybe<Array<{ __typename?: 'Position' } & PositionFragment>>;
    };

export type ActorFragment = { __typename?: 'Actor' } & Pick<Actor, '_id'> & {
        properties: { __typename?: 'ActorProperties' } & Pick<
            ActorProperties,
            'lastname' | 'firstname' | 'phone' | 'mobile' | 'address' | 'zipcode' | 'town'
        >;
    };

export type AreaWithRemarksFragment = { __typename?: 'Area' } & Pick<Area, '_id'> & {
        properties: { __typename?: 'AreaProperties' } & AreaPropertiesFragment;
        actors?: Maybe<
            Array<
                { __typename?: 'Actor' } & {
                    properties: { __typename?: 'ActorProperties' } & Pick<ActorProperties, 'lastname' | 'firstname'>;
                }
            >
        >;
        reports?: Maybe<
            Array<
                { __typename?: 'Report' } & Pick<Report, '_id'> & {
                        properties: { __typename?: 'ReportProperties' } & Pick<ReportProperties, 'name' | 'date'>;
                        actors?: Maybe<Array<{ __typename?: 'Actor' } & ActorFragment>>;
                        remarks?: Maybe<Array<{ __typename?: 'Remark' } & RemarkFragment>>;
                    }
            >
        >;
    };

export type GetProjectAreaReportsQueryVariables = Exact<{
    projectId: Scalars['ID'];
    areaId: Scalars['ID'];
}>;

export type GetProjectAreaReportsQuery = { __typename?: 'Query' } & {
    project?: Maybe<
        { __typename?: 'Project' } & Pick<Project, '_id'> & {
                properties: { __typename?: 'ProjectProperties' } & Pick<ProjectProperties, 'name'>;
            }
    >;
    area?: Maybe<{ __typename?: 'Area' } & AreaWithRemarksFragment>;
};

export type AreaPropertiesFragment = { __typename?: 'AreaProperties' } & Pick<
    AreaProperties,
    | 'ID_UNIQUE'
    | 'SURFACE'
    | 'NUMERO_COMMERCIAL_LOT'
    | 'TYPE_LOT'
    | 'ETAGE'
    | 'CHANTIER'
    | 'BAT'
    | 'ADRESSE'
    | 'CP_VILLE'
    | 'name'
    | 'type'
    | 'surface'
    | 'level'
    | 'NOM'
    | 'TEL'
>;

export type AreaFragment = { __typename?: 'Area' } & Pick<Area, '_id'> & {
        properties: { __typename?: 'AreaProperties' } & AreaPropertiesFragment;
        actors?: Maybe<Array<{ __typename?: 'Actor' } & ActorFragment>>;
        reports?: Maybe<
            Array<
                { __typename?: 'Report' } & Pick<Report, '_id'> & {
                        remarks?: Maybe<Array<{ __typename?: 'Remark' } & Pick<Remark, '_id'>>>;
                    }
            >
        >;
    };

export type GetProjectAreasQueryVariables = Exact<{
    projectId: Scalars['ID'];
}>;

export type GetProjectAreasQuery = { __typename?: 'Query' } & {
    project?: Maybe<
        { __typename?: 'Project' } & Pick<Project, '_id'> & {
                properties: { __typename?: 'ProjectProperties' } & Pick<ProjectProperties, 'name'>;
                areas?: Maybe<Array<{ __typename?: 'Area' } & AreaFragment>>;
            }
    >;
};

export type ProjectFragment = { __typename?: 'Project' } & Pick<Project, '_id'> & {
        properties: { __typename?: 'ProjectProperties' } & Pick<ProjectProperties, 'name'>;
        image?: Maybe<
            { __typename?: 'S3Document' } & {
                url?: Maybe<{ __typename?: 'S3DocumentUrl' } & Pick<S3DocumentUrl, 'thumbnail' | 'original'>>;
            }
        >;
        areas?: Maybe<Array<{ __typename?: 'Area' } & Pick<Area, '_id'>>>;
    };

export type GetProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectsQuery = { __typename?: 'Query' } & {
    projects: Array<{ __typename?: 'Project' } & ProjectFragment>;
};

export type DirectoryFragment = { __typename?: 'ProjectDocuments' } & {
    files?: Maybe<Array<{ __typename?: 'ProjectDocuments' } & FileFragment>>;
} & FileFragment;

export type FileFragment = { __typename?: 'ProjectDocuments' } & Pick<
    ProjectDocuments,
    '_id' | 'file_type' | 'root' | 'parents'
> & {
        properties?: Maybe<{ __typename?: 'S3DocumentProperties' } & Pick<S3DocumentProperties, 'name'>>;
        info?: Maybe<{ __typename?: 'Info' } & Pick<Info, 'creation_date'>>;
        url?: Maybe<{ __typename?: 'S3DocumentUrl' } & Pick<S3DocumentUrl, 'thumbnail' | 'original'>>;
    };

export type GetRootDirectoryQueryVariables = Exact<{
    projectId: Scalars['ID'];
}>;

export type GetRootDirectoryQuery = { __typename?: 'Query' } & {
    project?: Maybe<
        { __typename?: 'Project' } & Pick<Project, '_id'> & {
                properties: { __typename?: 'ProjectProperties' } & Pick<ProjectProperties, 'name'>;
                documents?: Maybe<{ __typename?: 'ProjectDocuments' } & DirectoryFragment>;
            }
    >;
};

export type GetSubDirectoryQueryVariables = Exact<{
    projectId: Scalars['ID'];
    root: Scalars['ID'];
    path?: Maybe<Array<Scalars['ID']>>;
}>;

export type GetSubDirectoryQuery = { __typename?: 'Query' } & {
    project?: Maybe<
        { __typename?: 'Project' } & Pick<Project, '_id'> & {
                properties: { __typename?: 'ProjectProperties' } & Pick<ProjectProperties, 'name'>;
            }
    >;
    document?: Maybe<{ __typename?: 'ProjectDocuments' } & DirectoryFragment>;
};

export const AreaPropertiesFragmentDoc = gql`
    fragment AreaProperties on AreaProperties {
        ID_UNIQUE
        SURFACE
        NUMERO_COMMERCIAL_LOT
        TYPE_LOT
        ETAGE
        CHANTIER
        BAT
        ADRESSE
        CP_VILLE
        name
        type
        surface
        level
        NOM
        TEL
    }
`;
export const ActorFragmentDoc = gql`
    fragment Actor on Actor {
        _id
        properties {
            lastname
            firstname
            phone
            mobile
            address
            zipcode
            town
        }
    }
`;
export const DocumentFragmentDoc = gql`
    fragment Document on S3Document {
        _id
        properties {
            name
        }
        info {
            creation_date
        }
        url {
            thumbnail
            original
        }
    }
`;
export const PositionFragmentDoc = gql`
    fragment Position on Position {
        _id
        document {
            ...Document
        }
        properties {
            x
            y
        }
    }
    ${DocumentFragmentDoc}
`;
export const RemarkFragmentDoc = gql`
    fragment Remark on Remark {
        _id
        properties {
            descriptionText
            localization {
                name
            }
        }
        observations {
            _id
            properties {
                message
            }
            info {
                user_create_name
                edit_date
            }
        }
        documents {
            ...Document
        }
        status {
            properties {
                status
                date
            }
            image {
                ...Document
            }
        }
        positions {
            ...Position
        }
    }
    ${DocumentFragmentDoc}
    ${PositionFragmentDoc}
`;
export const AreaWithRemarksFragmentDoc = gql`
    fragment AreaWithRemarks on Area {
        _id
        properties {
            ...AreaProperties
        }
        actors {
            properties {
                lastname
                firstname
            }
        }
        reports {
            _id
            properties {
                name
                date
            }
            actors {
                ...Actor
            }
            remarks {
                ...Remark
            }
        }
    }
    ${AreaPropertiesFragmentDoc}
    ${ActorFragmentDoc}
    ${RemarkFragmentDoc}
`;
export const AreaFragmentDoc = gql`
    fragment Area on Area {
        _id
        properties {
            ...AreaProperties
        }
        actors {
            ...Actor
        }
        reports {
            _id
            remarks {
                _id
            }
        }
    }
    ${AreaPropertiesFragmentDoc}
    ${ActorFragmentDoc}
`;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
        _id
        properties {
            name
        }
        image {
            url {
                thumbnail
                original
            }
        }
        areas {
            _id
        }
    }
`;
export const FileFragmentDoc = gql`
    fragment File on ProjectDocuments {
        _id
        properties {
            name
        }
        info {
            creation_date
        }
        url {
            thumbnail
            original
        }
        file_type
        root
        parents
    }
`;
export const DirectoryFragmentDoc = gql`
    fragment Directory on ProjectDocuments {
        ...File
        files {
            ...File
        }
    }
    ${FileFragmentDoc}
`;
export const GetProjectAreaReportsDocument = gql`
    query getProjectAreaReports($projectId: ID!, $areaId: ID!) {
        project(id: $projectId) {
            _id
            properties {
                name
            }
        }
        area(id: $areaId) {
            ...AreaWithRemarks
        }
    }
    ${AreaWithRemarksFragmentDoc}
`;

/**
 * __useGetProjectAreaReportsQuery__
 *
 * To run a query within a React component, call `useGetProjectAreaReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAreaReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAreaReportsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useGetProjectAreaReportsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectAreaReportsQuery, GetProjectAreaReportsQueryVariables>
) {
    return ApolloReactHooks.useQuery<GetProjectAreaReportsQuery, GetProjectAreaReportsQueryVariables>(
        GetProjectAreaReportsDocument,
        baseOptions
    );
}
export function useGetProjectAreaReportsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectAreaReportsQuery, GetProjectAreaReportsQueryVariables>
) {
    return ApolloReactHooks.useLazyQuery<GetProjectAreaReportsQuery, GetProjectAreaReportsQueryVariables>(
        GetProjectAreaReportsDocument,
        baseOptions
    );
}
export type GetProjectAreaReportsQueryHookResult = ReturnType<typeof useGetProjectAreaReportsQuery>;
export type GetProjectAreaReportsLazyQueryHookResult = ReturnType<typeof useGetProjectAreaReportsLazyQuery>;
export type GetProjectAreaReportsQueryResult = ApolloReactCommon.QueryResult<
    GetProjectAreaReportsQuery,
    GetProjectAreaReportsQueryVariables
>;
export const GetProjectAreasDocument = gql`
    query getProjectAreas($projectId: ID!) {
        project(id: $projectId) {
            _id
            properties {
                name
            }
            areas {
                ...Area
            }
        }
    }
    ${AreaFragmentDoc}
`;

/**
 * __useGetProjectAreasQuery__
 *
 * To run a query within a React component, call `useGetProjectAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAreasQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectAreasQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectAreasQuery, GetProjectAreasQueryVariables>
) {
    return ApolloReactHooks.useQuery<GetProjectAreasQuery, GetProjectAreasQueryVariables>(
        GetProjectAreasDocument,
        baseOptions
    );
}
export function useGetProjectAreasLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectAreasQuery, GetProjectAreasQueryVariables>
) {
    return ApolloReactHooks.useLazyQuery<GetProjectAreasQuery, GetProjectAreasQueryVariables>(
        GetProjectAreasDocument,
        baseOptions
    );
}
export type GetProjectAreasQueryHookResult = ReturnType<typeof useGetProjectAreasQuery>;
export type GetProjectAreasLazyQueryHookResult = ReturnType<typeof useGetProjectAreasLazyQuery>;
export type GetProjectAreasQueryResult = ApolloReactCommon.QueryResult<
    GetProjectAreasQuery,
    GetProjectAreasQueryVariables
>;
export const GetProjectsDocument = gql`
    query getProjects {
        projects(filter: [SHARED_VIA_SUBGROUPS]) {
            ...Project
        }
    }
    ${ProjectFragmentDoc}
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
    return ApolloReactHooks.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
}
export function useGetProjectsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
    return ApolloReactHooks.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = ApolloReactCommon.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetRootDirectoryDocument = gql`
    query getRootDirectory($projectId: ID!) {
        project(id: $projectId) {
            _id
            properties {
                name
            }
            documents {
                ...Directory
            }
        }
    }
    ${DirectoryFragmentDoc}
`;

/**
 * __useGetRootDirectoryQuery__
 *
 * To run a query within a React component, call `useGetRootDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootDirectoryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetRootDirectoryQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetRootDirectoryQuery, GetRootDirectoryQueryVariables>
) {
    return ApolloReactHooks.useQuery<GetRootDirectoryQuery, GetRootDirectoryQueryVariables>(
        GetRootDirectoryDocument,
        baseOptions
    );
}
export function useGetRootDirectoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRootDirectoryQuery, GetRootDirectoryQueryVariables>
) {
    return ApolloReactHooks.useLazyQuery<GetRootDirectoryQuery, GetRootDirectoryQueryVariables>(
        GetRootDirectoryDocument,
        baseOptions
    );
}
export type GetRootDirectoryQueryHookResult = ReturnType<typeof useGetRootDirectoryQuery>;
export type GetRootDirectoryLazyQueryHookResult = ReturnType<typeof useGetRootDirectoryLazyQuery>;
export type GetRootDirectoryQueryResult = ApolloReactCommon.QueryResult<
    GetRootDirectoryQuery,
    GetRootDirectoryQueryVariables
>;
export const GetSubDirectoryDocument = gql`
    query getSubDirectory($projectId: ID!, $root: ID!, $path: [ID!]) {
        project(id: $projectId) {
            _id
            properties {
                name
            }
        }
        document(root: $root, path: $path) {
            ...Directory
        }
    }
    ${DirectoryFragmentDoc}
`;

/**
 * __useGetSubDirectoryQuery__
 *
 * To run a query within a React component, call `useGetSubDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubDirectoryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      root: // value for 'root'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetSubDirectoryQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubDirectoryQuery, GetSubDirectoryQueryVariables>
) {
    return ApolloReactHooks.useQuery<GetSubDirectoryQuery, GetSubDirectoryQueryVariables>(
        GetSubDirectoryDocument,
        baseOptions
    );
}
export function useGetSubDirectoryLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubDirectoryQuery, GetSubDirectoryQueryVariables>
) {
    return ApolloReactHooks.useLazyQuery<GetSubDirectoryQuery, GetSubDirectoryQueryVariables>(
        GetSubDirectoryDocument,
        baseOptions
    );
}
export type GetSubDirectoryQueryHookResult = ReturnType<typeof useGetSubDirectoryQuery>;
export type GetSubDirectoryLazyQueryHookResult = ReturnType<typeof useGetSubDirectoryLazyQuery>;
export type GetSubDirectoryQueryResult = ApolloReactCommon.QueryResult<
    GetSubDirectoryQuery,
    GetSubDirectoryQueryVariables
>;
