import React from 'react';
import styled from 'styled-components';
import SearchIcon from '../static/img/search.svg';

const Search: React.FC<
    { className?: string } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ className, ...rest }) => {
    return (
        <div className={className}>
            <input {...rest} />
        </div>
    );
};

export default styled(Search)`
    display: flex;

    input {
        border-radius: 4px;
        border: 1px solid #e3e7f0;
        padding: 10px 15px;
        outline: 0;
        transition-duration: 0.3s;

        background: url(${SearchIcon}) no-repeat scroll 12px 9px;
        padding-left: 40px;

        font-size: 14px;
        color: ${({ theme }) => theme.blackTitle};

        &::placeholder {
            font-size: 14px;
            color: #a8b2c7;
        }

        &:hover,
        &:focus {
            box-shadow: var(--main_shadow);
        }
    }
`;
