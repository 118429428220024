import i18n from 'i18next';
import axios from 'axios';
import config from './config';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const fallBackLocales: Record<string, string> = { fr: 'fr_FR', en: 'en_GB', nl: 'nl_NL', pt: 'pt_PT', es: 'es_ES' };

const defaultLocale: string = 'en_GB';

const getTranslations = async (locale: string): Promise<Record<string, string>> => {
    const response = await axios.get(
        `https://s3.${config.TRANSLATION_BUCKET_REGION}.amazonaws.com/${config.TRANSLATION_BUCKET}/${locale}`
    );

    return response.data;
};

const locale = window.navigator.language.replace('-', '_');

i18n.use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: locale,
        fallbackLng: defaultLocale, // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            nsMode: 'default',
            wait: false
        },
        backend: {
            loadPath: '{{lng}}.json',
            parse: (data: Record<string, string>) => data,
            crossDomain: true,
            ajax: async (
                url: string,
                options: Record<string, string>,
                callback: (data: Record<string, string> | null, status: { status: number }) => void
            ) => {
                try {
                    const response = await getTranslations(url);
                    callback(response, { status: 200 });
                } catch (err) {
                    if (fallBackLocales[locale.substr(0, 2)]) {
                        try {
                            const response = await getTranslations(`${fallBackLocales[locale.substr(0, 2)]}.json`);
                            callback(response, { status: 200 });
                        } catch (err) {
                            callback(null, { status: err.response.status });
                        }
                    } else {
                        callback(null, { status: err.response.status });
                    }
                }
            }
        }
    });
