import React, { useState, useEffect } from 'react';
import NoData from 'components/NoData';

import styled from 'styled-components';
import Ariane from 'components/Ariane';
import ErrorModal from 'components/modal/ErrorModal';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loading';
import FileItem from 'components/FileItem';
import Toolbar from 'components/Toolbar';
import Search from 'components/Search';
import Fuse from 'fuse.js';
import { DirectoryFragment } from 'generated/graphql';

const options = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['properties.name', 'info.creation_date']
};

const Document: React.FC<{
    projectId?: string;
    className?: string;
    file?: DirectoryFragment;
    error: boolean;
    loading: boolean;
    setPath: (path: { label: string; _id: string }[]) => void;
    currentPath: { label: string; _id: string }[];
    projectName?: string;
}> = ({ file, loading, error, className, setPath, currentPath, projectName }) => {
    const [modalError, setError] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');

    useEffect(() => setError(error), [error]);
    const toggle = () => setError(!modalError);

    const [t] = useTranslation();

    const currentArianeLabel = `${projectName ? `${projectName} : Documents` : ''}`;

    const fuse = new Fuse(file?.files || [], options);

    const files = search ? fuse.search(search).map(({ item }) => item) : file?.files;

    return (
        <div className={className}>
            <Toolbar>
                <Ariane
                    previous={[{ url: '/', label: 'Projets' }]}
                    current={
                        currentPath && currentPath.length > 0
                            ? {
                                  label: currentArianeLabel,
                                  onClick: () => {
                                      setPath([]);
                                  }
                              }
                            : currentArianeLabel
                    }
                    next={currentPath.map(({ label, _id }, index: number) => ({
                        onClick: () => {
                            setPath(currentPath.slice(0, index + 1));
                        },
                        label
                    }))}
                />
                <Search
                    type="text"
                    placeholder="Rechercher"
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                />
            </Toolbar>
            {loading && <Loader />}
            {files && files.length > 0 ? (
                <div className="file_grid">
                    {files.map(doc => (
                        <FileItem
                            key={doc._id}
                            file={doc}
                            openFolder={() => {
                                if (doc.file_type === 'folder') {
                                    setPath([...currentPath, { _id: doc._id, label: doc.properties?.name || '' }]);
                                }
                            }}
                        />
                    ))}
                </div>
            ) : (
                !loading && <NoData />
            )}

            <ErrorModal opened={modalError} close={toggle}>
                {t('ErrorMessage_ErrorMessage')}
            </ErrorModal>
        </div>
    );
};

export default styled(Document)`
    .file_grid {
        display: grid;
        grid-template-columns: repeat(3, auto);
        row-gap: 40px;

        @media all and (min-width: 1150px) {
            grid-template-columns: repeat(5, auto);
        }
    }
`;
