import React, { useState, useCallback } from 'react';
import Login from '../pages/Login';
import Router from './Router';
import { useApolloClient } from '@apollo/client';
import { navigate } from '@reach/router';

type User = {
    firstname: string;
    lastname: string;
    token: string;
    email: string;
};

const defaultUser = { firstname: '', lastname: '', token: '', email: '' };

const user: User =
    (window.localStorage.getItem('user') && (JSON.parse(window.localStorage.getItem('user') as string) as User)) ||
    defaultUser;

export const Logged = React.createContext({ user, login: (user: User): void => {}, logout: (): void => {} });

const Main: React.FC = () => {
    const [currentUser, setCurrentUser] = useState(user);

    const logWithUser = (user: User = defaultUser) => {
        window.localStorage.setItem('user', JSON.stringify(user));
        setCurrentUser(user);
    };

    const client = useApolloClient();

    const logout = useCallback(() => {
        logWithUser();
        client.resetStore();
        navigate('/');
    }, [client]);

    const login = (user: User) => {
        logWithUser(user);
    };

    return (
        <Logged.Provider value={{ user: currentUser, logout, login }}>
            {currentUser.token ? <Router /> : <Login />}
        </Logged.Provider>
    );
};

export default Main;
