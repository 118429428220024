import React, { useContext } from 'react';
import { Logged } from './Main';
import styled from 'styled-components';
import downIcon from '../static/img/down-blue.png';
import logo from '../static/img/logo-pichet.jpg';
import { ReactComponent as Disconnect } from '../static/img/disconnect.svg';
import { Link } from '@reach/router';
import edit from 'static/img/edit.png';

const Header: React.FC<{ className?: string }> = ({ className }) => {
    const { user, logout } = useContext(Logged);

    return (
        <header className={className}>
            <Link to="/" className="logo">
                <img src={logo} alt="Pichet logo" />
            </Link>

            <div className="usermenu">
                <span className="username">
                    <span>{user.firstname ? `${user.firstname} ${user.lastname}` : user.email}</span>
                    <img className="usermenu_icon" src={downIcon} alt="open" />
                </span>
                <div className="usermenu_wrapper">
                    <div className="usermenu_content">
                        <Link to="/user" className="usermenu_action">
                            <img src={edit} alt="edit" className="action_disconnect" /> Préférences
                        </Link>
                        <button className="usermenu_action" onClick={logout}>
                            <Disconnect className="action_disconnect" /> Se déconnecter
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default styled(Header)`
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: var(--header_height);
    box-shadow: var(--main_shadow);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: white;
    z-index: 1;

    .logo {
        text-decoration: none;

        img {
            width: 160px;
        }
    }

    .usermenu_content {
        position: absolute;
        right: 0;
        visibility: hidden;
        background: white;
        border-radius: 4px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

        &::before {
            content: '▲';
            display: block;
            position: absolute;
            right: 15px;
            top: -8px;
            color: white;
            font-size: 10px;
            width: 10px;
            height: 10px;
            z-index: 3;
        }

        &::after {
            content: '▲';
            display: block;
            position: absolute;
            right: 15px;
            top: -9px;
            color: white;
            font-size: 10px;
            width: 10px;
            height: 10px;
        }
    }

    .username {
        flex: 1;
        line-height: var(--header_height);
        font-size: 14px;
        font-weight: 500;
    }

    .usermenu {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-direction: column;

        &:focus,
        &:hover {
            .usermenu_content {
                visibility: visible;
            }
        }
    }

    .usermenu_wrapper {
        position: relative;
        width: 100%;
        height: 0;
    }

    .usermenu_icon {
        margin-left: 5px;
        width: 11px;
    }

    .usermenu_action {
        border: none;
        background: none;
        white-space: nowrap;
        height: 50px;
        width: 200px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        text-decoration: none;
        color: black;
    }

    .action_disconnect {
        width: 18px;
        margin-right: 12px;
    }
`;
