import React, { useState } from 'react';
import Root from './Root';
import SubDirectory from './SubDirectory';

const Document: React.FC<{ path?: string; projectId?: string }> = ({ projectId = '' }) => {
    const [path, setPath] = useState<{ label: string; _id: string }[]>([]);

    const [root, setRoot] = useState<string>();

    return path && path.length > 0 && root ? (
        <SubDirectory setPath={setPath} projectId={projectId} path={path} root={root} />
    ) : (
        <Root setPath={setPath} setRoot={setRoot} projectId={projectId} />
    );
};

export default Document;
