import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useContext } from 'react';
import { Logged } from '../components/Main';
import * as Sentry from '@sentry/browser';
import { print } from 'graphql/language/printer';

export default <TData, TVariables>(
    useQuery: (options?: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
    const { user, logout } = useContext(Logged);

    const result = useQuery({
        ...options,
        context: {
            ...(options ? options.context : {}),
            headers: {
                ...(options && options.context ? options.context.headers : {}),
                Authorization: `Bearer ${user.token}`
            }
        }
    });

    if (
        result.error &&
        result.error.graphQLErrors &&
        result.error.graphQLErrors.find(err => err.extensions && err.extensions.code === 'UNAUTHENTICATED')
    ) {
        if (logout) {
            logout();
        }
    } else if (result.error) {
        Sentry.withScope(scope => {
            scope.setUser({
                username: `${user.firstname} ${user.lastname}`,
                email: user.email
            });
            scope.setExtra('error', result.error);
            if (options) {
                scope.setExtra('variables', options.variables);
                if (options.query) {
                    scope.setExtra('query', print(options.query));
                }
            }

            Sentry.captureException(result.error);
        });
    }

    return result;
};
