import React from 'react';
import ReactImage from 'react-image';
import loader from '../static/img/img-loader.svg';

const Image: React.FC<{
    className?: string;
    image?: {
        url?: {
            thumbnail: string;
            original: string;
        } | null;
    };
    onClick?: () => void;
    alt?: string;
    fallback?: string;
}> = ({ image, fallback, ...rest }) =>
    image || fallback ? (
        <ReactImage
            src={image?.url ? [image.url.thumbnail, image.url.original, fallback || ''] : [fallback || '']}
            {...rest}
            loader={<img className={rest.className} src={loader} alt="loader" />}
        />
    ) : null;

export default Image;
