import React, { useState, useEffect } from 'react';
import NoData from 'components/NoData';
import styled from 'styled-components';
import ProjectItem from './ProjectItem';
import ErrorModal from 'components/modal/ErrorModal';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loading';

import useQuery from 'hook/useQuery';
import Fuse from 'fuse.js';
import Search from 'components/Search';
import { useGetProjectsQuery } from 'generated/graphql';

const options = {
    shouldSort: true,
    tokenize: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['properties.name']
};

const Project = ({ className }: { className?: string; path?: string }) => {
    const { data, error: requestError, loading } = useQuery(useGetProjectsQuery);

    const [search, setSearch] = useState<string>('');

    const [error, setError] = useState<boolean>(false);
    useEffect(() => setError(!!requestError), [requestError]);
    const toggle = () => setError(!error);

    const [t] = useTranslation();

    const fuse = new Fuse(data?.projects || [], options);

    const projects = search ? fuse.search(search).map(({ item }) => item) : data?.projects;

    return (
        <>
            <div className={className}>
                <div className="toolbar">
                    <Search
                        type="text"
                        placeholder="Rechercher"
                        value={search}
                        onChange={event => setSearch(event.target.value)}
                    />
                </div>
                {loading && <Loader />}
                {projects && projects.length > 0 ? (
                    <div className="projects">
                        {projects.map(project => (
                            <ProjectItem key={project._id} project={project} />
                        ))}
                    </div>
                ) : (
                    !loading && <NoData />
                )}
            </div>
            <ErrorModal opened={error} close={toggle}>
                {t('ErrorMessage_ErrorMessage')}
            </ErrorModal>
        </>
    );
};

export default styled(Project)`
    background: #f3f5fb;
    min-height: calc(100vh - var(--header_height));

    .toolbar {
        display: flex;
        flex-direction: row-reverse;
        padding: 20px 10px;
    }

    .projects {
        display: grid;
        grid-template-columns: repeat(auto-fill, 260px);
        grid-template-rows: 300px;
        row-gap: 30px;
        column-gap: 30px;
        padding: 45px 20px;
        box-sizing: border-box;
        justify-content: center;
    }
`;
