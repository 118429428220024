import { AxiosPromise } from 'axios';
import api, { buildHeader } from './utils';

export const login = (
    email: string,
    password: string
): AxiosPromise<
    | {
          login: boolean;
          message: string;
      }
    | { login: boolean; token: string; body: { firstname: string; lastname: string } }
> => api.post('/login_web', { email, password });

export const updatePassword = (token: string, password: string): AxiosPromise =>
    api.put('arbox/account/password', { password }, buildHeader(token));
