import React from 'react';
import FileModal from 'components/modal/FileModal';
import { addPositions } from '@archireport/pdf-position-plan';
import axios from 'axios';
import ErrorModal from 'components/modal/ErrorModal';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import { DocumentFragment } from 'generated/graphql';

const LazyPdfViewer = React.lazy(() => import('components/FileItem/Pdf'));

const PdfWithPositionsModal: React.FC<{
    document: DocumentFragment & { positions: { x: number; y: number; name: string; status: number }[] };
    opened: boolean;
    close: () => void;
}> = ({ document, opened, close }) => {
    const [file, setFile] = React.useState<
        DocumentFragment & { positions: { x: number; y: number; name: string; status: number }[] }
    >();
    const [error, setError] = React.useState<boolean>(false);

    const toggle = () => setError(!error);

    const [t] = useTranslation();

    React.useEffect(() => {
        (async () => {
            try {
                if (document?.url) {
                    const response = await axios.get(document.url.original, {
                        responseType: 'arraybuffer'
                    });

                    const fileWithPosition = await addPositions(new Buffer(response.data), document.positions);

                    setFile({
                        ...document,
                        url: {
                            ...document.url,
                            original:
                                fileWithPosition !== null
                                    ? window.URL.createObjectURL(
                                          new Blob([fileWithPosition], { type: 'application/pdf' })
                                      )
                                    : ''
                        }
                    });
                }
            } catch (err) {
                Sentry.withScope(scope => {
                    scope.setExtra('pdf', document);
                    Sentry.captureException(err);
                });
                setError(true);
            }
        })();
    }, [document]);

    return (
        <>
            {file && (
                <FileModal file={file} close={close} opened={opened}>
                    <LazyPdfViewer file={file} />
                </FileModal>
            )}
            <ErrorModal opened={error} close={toggle}>
                {t('ErrorMessage_ErrorMessage')}
            </ErrorModal>
        </>
    );
};

export default PdfWithPositionsModal;
