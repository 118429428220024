import React from 'react';
import useQuery from '../../hook/useQuery';
import Document from './Document';
import { useGetSubDirectoryQuery } from 'generated/graphql';

interface Project {
    _id: string;
    properties: {
        name: string;
    };
}

const SubDirectory: React.FC<{
    projectId?: string;
    setPath: (path: { label: string; _id: string }[]) => void;
    path: { label: string; _id: string }[];
    root: string;
}> = ({ projectId = '', setPath, path, root }) => {
    const { data, loading, error } = useQuery(useGetSubDirectoryQuery, {
        variables: {
            projectId,
            root,
            path: path.map(({ _id }) => _id)
        }
    });

    return data?.document ? (
        <Document
            loading={loading}
            error={!!error}
            setPath={setPath}
            currentPath={path}
            file={data.document}
            projectName={data.project?.properties.name || ''}
        />
    ) : null;
};

export default SubDirectory;
