import React from 'react';
import { ReactComponent as LoaderPicto } from '../static/img/loader-light-blue.svg';
import styled from 'styled-components';

const StyledLoaderPicto = styled(LoaderPicto)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 30px;
    z-index: 2;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #ffffff80;
    backdrop-filter: blur(0.5rem);
`;

const Loader: React.FC = () => {
    return (
        <>
            <Overlay />
            <StyledLoaderPicto />
        </>
    );
};

export default Loader;
