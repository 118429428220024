import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyle';
import './i18n';
import Main from './components/Main';
import theme from './theme';
import Loading from './components/Loading';
import styled from 'styled-components';
import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import config from './config';
import * as Sentry from '@sentry/browser';

const client = new ApolloClient({
    link: ApolloLink.from([new HttpLink({ uri: `${config.ARCHIREPORT_WEB_API_URL}/graphql` })]),
    cache: new InMemoryCache(),
    assumeImmutableResults: true
});

const Layout = styled.div`
    --header_height: 60px;
    --main_shadow: 0 0 15px rgba(28, 53, 150, 0.13);
    --hard_shadow: 0 0 20px rgba(28, 53, 150, 0.22);
`;

class App extends React.Component {
    state = { error: null, eventId: '' };

    componentDidCatch(error: Error) {
        this.setState({ error });
        Sentry.withScope(scope => {
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (this.state.error) {
            // render fallback UI
            return (
                <button
                    type="button"
                    onClick={() => Sentry.showReportDialog({ lang: 'fr', eventId: this.state.eventId })}
                >
                    Une erreur est survenue
                </button>
            );
        }
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <GlobalStyle />
                    <Suspense fallback={<Loading />}>
                        <ApolloProvider client={client}>
                            <Main />
                        </ApolloProvider>
                    </Suspense>
                </Layout>
            </ThemeProvider>
        );
    }
}

export default App;
