import React from 'react';
import styled from 'styled-components';

import placeholder from '../../static/img/placeholder_proj.jpg';
import { Link } from '@reach/router';
import Image from '../../components/Image';
import { ProjectFragment } from 'generated/graphql';

const ProjectItem = ({
    project: {
        _id,
        properties: { name },
        image,
        areas
    },
    className
}: {
    project: ProjectFragment;
    className?: string;
}) => {
    return (
        <Link to={`/project/${_id}`} className={className}>
            {image && <Image className="project_picture" image={image} alt={name} fallback={placeholder} />}

            <section className="project_description">
                <h3 className="project_title">{name}</h3>
                <p className="project_areas">{`${areas ? areas.length : 0} logements`}</p>
            </section>
        </Link>
    );
};

export default styled(ProjectItem)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 6px;
    box-shadow: var(--main_shadow);
    cursor: pointer;
    max-height: 300px;
    overflow: hidden;

    @media (prefers-reduced-motion: no-preference) {
        transition: ease-in-out 0.2s;
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    }

    .project_picture {
        object-fit: cover;
        width: 100%;
        height: 140px;
    }

    .project_description {
        display: flex;
        flex: 1;
        margin: 15px;
        flex-direction: column;
        justify-content: space-between;
    }

    .project_title {
        font-weight: 500;
        font-size: 19px;
        color: #000;
    }

    .project_areas {
        font-weight: 400;
        font-size: 15px;
        color: ${({ theme }) => theme.greyTitle};
    }
`;
