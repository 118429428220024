import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';

export default axios.create({
    baseURL: config.ARCHIREPORT_WEB_API_URL,
    headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'application/json', ArchiReportOrigin: 'savpichet' }
});

/**
 * Check if token is valid.
 * @param token is the authentification token
 */
export const buildHeader = (token: string): AxiosRequestConfig => ({
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
});
