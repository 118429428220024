import React from 'react';
import useQuery from '../../hook/useQuery';
import Document from './Document';
import { useGetRootDirectoryQuery } from 'generated/graphql';

const Root: React.FC<{
    projectId?: string;
    setPath: (path: { label: string; _id: string }[]) => void;
    setRoot: (path: string) => void;
}> = ({ projectId = '', setPath, setRoot }) => {
    const { data, loading, error } = useQuery(useGetRootDirectoryQuery, {
        variables: { projectId }
    });

    if (data?.project && data.project.documents) {
        setRoot(data.project.documents._id);
    }

    return data?.project?.documents ? (
        <Document
            loading={loading}
            error={!!error}
            setPath={setPath}
            currentPath={[]}
            file={data.project.documents}
            projectName={data.project.properties.name}
        />
    ) : null;
};

export default Root;
