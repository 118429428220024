import React from 'react';
import styled from 'styled-components';
import MapPin from 'static/img/map-pin.svg';
import Modal from './Modal';
import { DocumentFragment } from 'generated/graphql';

const Button = styled.button`
    display: flex;
    flex-direction: row;
    width: calc(50% - 10px);
    align-items: center;
    background: white;
    border-radius: 4px;
    padding: 8px 10px;
    border: 1px solid #e5e9f4;
    cursor: pointer;
    outline: 0;

    margin: 0px 5px 10px 5px;

    .map-pin {
        width: 18px;
    }

    .positions-texts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        margin-left: 12px;

        .pdf-title {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.blackTitle};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 230px;
            text-align: left;
        }
        .positions-count {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.greyTitle};
        }
    }

    &:hover {
        background-color: #fafbff;
    }
    &:focus {
        background-color: #e4e7ef;
    }
`;

const Plan: React.FC<{
    document: DocumentFragment & { positions: { x: number; y: number; name: string; status: number }[] };
}> = ({ document }) => {
    const [opened, setOpened] = React.useState(false);

    const toggle = () => setOpened(!opened);

    return (
        <>
            <Button onClick={toggle}>
                <img className="map-pin" src={MapPin} alt="Position icon" />
                <div className="positions-texts">
                    <span className="pdf-title">{`${document.properties?.name || ''}`}</span>
                    <span className="positions-count">{`${document.positions.length} position${
                        document.positions.length > 1 ? 's' : ''
                    }`}</span>
                </div>
            </Button>
            <Modal document={document} opened={opened} close={toggle} />
        </>
    );
};

export default Plan;
