import React, { useContext, useState } from 'react';
import { Logged } from '../components/Main';
import { Formik } from 'formik';
import { login as apiLogin } from '../api/authent';
import styled from 'styled-components';
import loginLogo from '../static/img/logo-login.png';
import background from '../static/img/login-bg-bot.png';
import { useTranslation } from 'react-i18next';
import ErrorModal from '../components/modal/ErrorModal';
import LoginButton from 'components/LoginButton';

const Login: React.FC<{ className?: string }> = ({ className }) => {
    const { login } = useContext(Logged);

    const [error, setError] = useState(false);
    const toggle = () => setError(!error);

    const { t } = useTranslation();

    return (
        <main className={className}>
            <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={async (values: { email: string; password: string }, { setSubmitting, setStatus }) => {
                    try {
                        const result = await apiLogin(values.email, values.password);

                        const data = result.data;

                        if (data.login) {
                            const {
                                token,
                                body: { firstname, lastname }
                            } = data as {
                                login: boolean;
                                token: string;
                                body: { firstname: string; lastname: string };
                            };

                            login({ token, firstname, lastname, email: values.email });
                        } else {
                            toggle();
                        }
                    } catch (err) {
                        toggle();
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (
                    <form className="form" onSubmit={handleSubmit}>
                        <img className="login-logo" src={loginLogo} alt="Pichet logo" />
                        <h1 className="title">{t('Account_Connexion')}</h1>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="form_input"
                            required
                            aria-label={t('Global_Email')}
                            placeholder={t('Global_Email')}
                        />
                        <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            className="form_input"
                            required
                            aria-label={t('Global_Password')}
                            placeholder={t('Global_Password')}
                        />
                        <LoginButton type="submit" disabled={isSubmitting}>
                            {t('Global_Validate')}
                        </LoginButton>

                        <ErrorModal opened={error} close={toggle}>
                            {t('ErrorMessage_ConnectionError')}
                        </ErrorModal>
                    </form>
                )}
            </Formik>
        </main>
    );
};

export default styled(Login)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: url(${background}) no-repeat bottom center/contain, linear-gradient(180deg, #7f98ff 0, #5e6ffa 40%);

    .login-logo {
        width: 173px;
    }

    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 100px;
    }

    .title {
        font-size: 32px;
        font-weight: 400;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .form_input {
        width: 300px;
        height: 50px;
        border-radius: 7px;
        border: none;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 15px;

        &::placeholder {
            text-transform: capitalize;
        }
    }
`;
