import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export default createGlobalStyle`


${normalize}

html, body, input, textarea{
   font-family: Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
}



`;
