import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import closeIcon from '../../static/img/close-red.png';
import useBlockScroll from '../../hook/useBlockScroll';

const ErrorModal: React.FC<{ className?: string; close: () => void; opened: boolean }> = ({
    className,
    children,
    opened,
    close
}) => {
    useBlockScroll(opened);

    return opened ? (
        <div className={className} aria-label="modal">
            <div className="content">{children}</div>
            <button className="close" onClick={close} aria-label="close">
                <img src={closeIcon} alt="close" />
            </button>
        </div>
    ) : null;
};

const StyledErrorModal = styled(ErrorModal)`
    position: absolute;
    top: 15px;
    background: white;
    max-width: 450px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.12);
    color: #465576;
    font-size: 18px;
    display: flex;
    z-index: 2;

    .content {
        flex: 1;
        padding: 15px;
    }

    .close {
        border: none;
        border-left: 1px solid #f0f0f0;
        background: white;
        border-radius: 0 10px 10px 0;
        width: fit-content;
        padding: 10px 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus,
        &:hover {
            background-color: #f8f8f8;
        }

        img {
            width: 14px;
        }
    }
`;

export default function Modal(props: { close: () => void; opened: boolean; children: ReactNode }) {
    return ReactDOM.createPortal(<StyledErrorModal {...props} />, document.querySelector('#modal') as Element);
}
