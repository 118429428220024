import React, { useState } from 'react';
import styled from 'styled-components';
import folder from '../../static/img/files/folder.png';
import video from '../../static/img/files/vid.png';
import pdf from '../../static/img/files/pdf.png';
import xls from '../../static/img/files/xls.png';
import doc from '../../static/img/files/word.png';
import FileModal from '../modal/FileModal';
import FileItemLayout from './FileItemLayout';
import NotSupported from './NotSupported';
import Video from './Video';
import Picture from './Picture';
import Loading from '../Loading';
import Image from '../Image';
import { FileFragment } from 'generated/graphql';

const Button = styled.button`
    background: white;
    border-radius: 10px;
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover,
    &:focus {
        box-shadow: 0 0 30px rgba(28, 53, 150, 0.12);
    }

    .picto {
        width: 45px;
    }

    .picture {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
`;

const LazyPdfViewer = React.lazy(() => import('./Pdf'));

const FileItem: React.FC<{
    file: FileFragment;
    className?: string;
    openFolder: () => void;
}> = ({ file, openFolder }) => {
    const [opened, setOpened] = useState(false);

    const toggle = () => setOpened(!opened);

    switch (file.file_type) {
        case 'folder':
            return (
                <FileItemLayout file={file}>
                    <Button className="picto_zone" onClick={openFolder}>
                        <img className="picto" src={folder} alt="folder" />
                    </Button>
                </FileItemLayout>
            );
        case 'video/mp4':
            return (
                <FileItemLayout file={file}>
                    <Button onClick={toggle} className="picto_zone">
                        <img className="picto" src={video} alt="video" />
                    </Button>
                    <FileModal file={file} opened={opened} close={toggle}>
                        <Video file={file} />
                    </FileModal>
                </FileItemLayout>
            );
        case 'application/pdf':
            return (
                <FileItemLayout file={file}>
                    <Button onClick={toggle} className="picto_zone">
                        <img className="picto" src={pdf} alt="PDF" />
                    </Button>
                    <FileModal file={file} opened={opened} close={toggle}>
                        <React.Suspense fallback={<Loading />}>
                            <LazyPdfViewer file={file} />
                        </React.Suspense>
                    </FileModal>
                </FileItemLayout>
            );
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return (
                <FileItemLayout file={file}>
                    <Button onClick={toggle} className="picto_zone">
                        <img className="picto" src={xls} alt="XLS" />
                    </Button>
                    <FileModal file={file} opened={opened} close={toggle}>
                        <NotSupported />
                    </FileModal>
                </FileItemLayout>
            );
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return (
                <FileItemLayout file={file}>
                    <Button onClick={toggle} className="picto_zone">
                        <img className="picto" src={doc} alt="DOC" />
                    </Button>
                    <FileModal file={file} opened={opened} close={toggle}>
                        <NotSupported />
                    </FileModal>
                </FileItemLayout>
            );
        default:
            if (file.file_type.includes('image') && file.url) {
                return (
                    <FileItemLayout file={file}>
                        <Button onClick={toggle} className="picto_zone">
                            <Image className="picture" image={file} alt={file.properties?.name || ''} />
                        </Button>
                        <FileModal file={file} opened={opened} close={toggle}>
                            <Picture src={file.url.original} alt={file.properties?.name || ''} />
                        </FileModal>
                    </FileItemLayout>
                );
            }
            return null;
    }
};

export default FileItem;
