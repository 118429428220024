import React, { ReactNode } from 'react';
import styled from 'styled-components';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { fr } from 'date-fns/locale';
import { FileFragment } from 'generated/graphql';

const FileItemLayout: React.FC<{
    children: ReactNode;
    className?: string;
    file: FileFragment;
}> = ({ children, className, file }) => {
    return (
        <figure className={className}>
            {children}
            <figcaption>
                <p className="file_name">{file.properties?.name}</p>
                {file.info?.creation_date && (
                    <p className="file_date">
                        {format(parseISO(file.info?.creation_date), 'dd/MMM/yyyy', { locale: fr })}
                    </p>
                )}
            </figcaption>
        </figure>
    );
};

export default styled(FileItemLayout)`
    width: 175px;
    margin: 0 auto;

    .file_name {
        font-weight: 500;
        font-size: 14px;
        color: #434c5e;
        margin-top: 10px;
    }

    .file_date {
        margin-top: 3px;
        font-weight: 400;
        font-size: 13px;
        color: ${({ theme }) => theme.greyTitle};
    }
`;
