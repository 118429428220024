import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import CloseButton from './CloseButton';
import useBlockScroll from '../../hook/useBlockScroll';

const FileModal: React.FC<{
    className?: string;
    close: () => void;
    opened: boolean;
    file: { properties?: { name?: string | null } | null; url?: { thumbnail: string; original: string } | null };
}> = ({ className, children, opened, close, file }) => {
    useBlockScroll(opened);

    return opened ? (
        <div className={className}>
            <div className="overlay" onClick={close} />
            <div className="modal" aria-label="modal">
                <div className="modal_toolbar">
                    <CloseButton onClick={close} />

                    <h2 className="title">{file.properties?.name}</h2>

                    {file && file.url ? (
                        <a className="download" href={file.url.original} download>
                            Télécharger
                        </a>
                    ) : (
                        <p>Indisponible</p>
                    )}
                </div>
                <div className="content">{children}</div>
            </div>
        </div>
    ) : null;
};

const StyledFileModal = styled(FileModal)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .overlay {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: hsla(0, 0%, 100%, 0.6);
        cursor: pointer;
        backdrop-filter: blur(2px);
    }

    .modal {
        z-index: 3;
        border-radius: 5px;
        max-width: 1200px;
        max-height: calc(100vh - 60px);
        background-color: #fff;
        box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
    }

    .modal_toolbar {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        min-height: 60px;
        align-items: center;
        box-shadow: 0 0 15px rgba(28, 53, 150, 0.1);
        z-index: 1;
    }

    .title {
        text-indent: 14px;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.blackTitle};
    }

    .download {
        background-color: #6071fc;
        border-radius: 30px;
        color: white;
        padding: 8px 15px;
        font-size: 15px;
        cursor: pointer;
        text-decoration: none;

        &:focus,
        &:hover {
            box-shadow: 0 5px 25px rgba(28, 53, 150, 0.25);
        }
    }

    .content {
        min-height: 200px;
        overflow: auto;
    }
`;

export default function Modal(props: {
    close: () => void;
    opened: boolean;
    children: ReactNode;
    file: { properties?: { name?: string | null } | null; url?: { thumbnail: string; original: string } | null };
}) {
    return ReactDOM.createPortal(<StyledFileModal {...props} />, document.querySelector('#modal') as Element);
}
