import React from 'react';
import { ReactComponent as CloseIcon } from '../../static/img/close.svg';
import styled from 'styled-components';

const CloseButton: React.FC<{ onClick: () => void; className?: string }> = ({ onClick, className }) => {
    return (
        <button className={className} onClick={onClick} aria-label="close">
            <CloseIcon />
        </button>
    );
};

export default styled(CloseButton)`
    width: 40px;
    border: none;
    background: none;
    padding: none;
    padding: 0;
    display: inline-flex;

    > svg {
        width: 30px;
        border-radius: 50%;

        &:hover,
        &:focus {
            box-shadow: 0 0 20px rgba(72, 66, 201, 0.2);
        }
    }
`;
