import React, { ReactNode } from 'react';
import styled from 'styled-components';
import RemarksImage from '../static/img/sidebar-rmks-link.png';
import DocImage from '../static/img/sidebar-doc-link.png';
import MenuLink from '../components/MenuLink';

const Layout: React.FC<{ path?: string; className?: string; children: ReactNode; projectId?: string }> = ({
    children,
    className,
    projectId
}) => {
    return (
        <main className={className}>
            <nav className="menu">
                <ul>
                    <li>
                        <MenuLink to={`/project/${projectId}`} className="menu_item">
                            <img src={RemarksImage} alt="Réserves" className="menu_item_picto" />
                            Réserves
                        </MenuLink>
                        <MenuLink to={`/project/${projectId}/documents`} className="menu_item">
                            <img src={DocImage} alt="Documents" className="menu_item_picto" />
                            Documents
                        </MenuLink>
                    </li>
                </ul>
            </nav>
            <div className="content">{children}</div>
        </main>
    );
};

export default styled(Layout)`
    display: flex;
    --menu-width: 160px;

    background-color: #f3f5fb;

    .menu {
        width: var(--menu-width);
        position: fixed;
        left: 0;
        top: var(--header_height);
        height: calc(100vh - var(--header_height));
        box-shadow: var(--main_shadow);
        background-color: white;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
    }

    .content {
        padding: 20px 20px;
        box-sizing: border-box;
        overflow: visible;
        min-height: calc(100vh - var(--header_height));
        height: 100%;
        margin-left: var(--menu-width);
        width: calc(100vw - var(--header_height));
    }

    .menu_item {
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
    }

    .menu_item_picto {
        width: 105px;
    }
`;
