import React from 'react';
import { Link, LinkGetProps } from '@reach/router';
import styled from 'styled-components';

const isActive = (className: string) => ({
    isCurrent,
    isPartiallyCurrent,
    location
}: LinkGetProps): { className: string } => ({
    className: `${className} ${
        isCurrent || (isPartiallyCurrent && !location.href.includes('/documents')) ? 'active' : '' //manage subentries of areas (but don’t consider documents as active)
    }`
});

const NavLink = (props: any) => <Link {...props} getProps={isActive(props.className)} />;

export default styled(NavLink)`
    color: ${({ theme }) => theme.greyTitle};

    &.active {
        background-color: #6071fc;
        color: #fff;
    }
`;
